import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../boxes/Modal";

const NotificationModal = ({message, onClose, open}) => {
  return (
    <Modal title="Notification!" onClose={onClose} open={open}>
      <div style={{textAlign: "center"}}>
        <p style={{fontSize: "1.6rem", lineHeight: "2.4rem", marginBottom: "2rem"}}>
          {message}
        </p>
        <button className="dashboard__primary-btn" onClick={onClose}>DISMISS</button>
      </div>
    </Modal>
  )
};

NotificationModal.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func
};

export default NotificationModal;