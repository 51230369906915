import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';
import { useTranslation } from 'react-i18next';

let LoginForm = (props) => {

    const {handleSubmit, pristine, submitting, language } = props;
    const { t } = useTranslation('login');

    return (
        <form action="" className="dashboard-form form" onSubmit={handleSubmit}>
          <span>
              <Field component="input" className="form-input" type="text" name="email" placeholder={t('login_email')} required/>
          </span>
          <span>
              <Field component="input" className="form-input" type="password" name="password" placeholder={t('login_password')} required/>
          </span>

          <input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value={t('login_button')} />
        </form>
    )
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func
};

LoginForm = reduxForm({
    form:'loginForm',
    destroyOnUnmount:false,
    initialValues: {remember_me: true}
})(LoginForm);

export default LoginForm;