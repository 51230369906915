import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../boxes/Modal";
import ExtensionSelectorComponent from "../ExtensionSelectorComponent";

const ExtensionSelectorModal = ({onClose, open, planId, planName, planExpires, userId, exclude, onComplete}) => {
  return (
    <Modal title="Add Extra Benefits" onClose={onClose} open={open}>
      <ExtensionSelectorComponent onClose={onClose} planId={planId} planName={planName} userId={userId} onComplete={onComplete}
                                  planExpires={planExpires} exclude={exclude}/>
    </Modal>
  )
};

ExtensionSelectorModal.propTypes = {
  onClose: PropTypes.func,
  planId: PropTypes.number,
  planName: PropTypes.string,
  planExpires:PropTypes.string,
  userId:PropTypes.string,
  exclude:PropTypes.array,
  onComplete: PropTypes.func
};

export default ExtensionSelectorModal;