import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { decodeResourceStatus } from '../../services/utilityService';

const EnrolleeListComponent = ({enrolleeList, history}) => (
  <div className="dashboard-card dashboard-table-wrap">
    <div className="dashboard-table-overflow">
      <table className="dashboard-table">
        <thead>
          <tr>
            <th className="dashboard-table-item dashboard-table-item__header">Enrollee Name</th>
            <th className="dashboard-table-item dashboard-table-item__header">Email</th>
            <th className="dashboard-table-item dashboard-table-item__header">Phone Number</th>
            <th className="dashboard-table-item dashboard-table-item__header">Plan</th>
            <th className="dashboard-table-item dashboard-table-item__header">Status</th>
          </tr>
        </thead>
        <tbody>
          {enrolleeList.map((enrollee) => (
            <tr key={enrollee.id}
                onClick={()=> {if(enrollee.active_status_id) history.push(`/dashboard/enrollees/${enrollee.id}`) }}
                style={{backgroundColor:`${enrollee.active_status_id === 4 && enrollee.has_profile === false  ? "#e8e8e8" : "" }`, cursor: "pointer"}}>
              <td className="dashboard-table-item dashboard-table-item__body">
                {`${enrollee.first_name}  ${enrollee.last_name}`}
              </td>
              <td className="dashboard-table-item dashboard-table-item__body">{enrollee.email_address}</td>
              <td className="dashboard-table-item dashboard-table-item__body">{enrollee.phone_number}</td>
              <td className="dashboard-table-item dashboard-table-item__body">{enrollee.product_name}</td>
              <td className="dashboard-table-item dashboard-table-item__body" style={{textTransform: "capitalize"}}>{enrollee.has_profile ? decodeResourceStatus(enrollee.active_status_id) : "Incomplete Profile"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

EnrolleeListComponent.propTypes = {
    enrolleeList: PropTypes.array
};

export default withRouter(EnrolleeListComponent);