import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';
import { getSupportedLocales } from './services/utilityService';

const primaryLocaleCode = getSupportedLocales("primary");
const supportedLanguages = [primaryLocaleCode, 'en-NG'];

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: supportedLanguages,
        fallbackLng: 'en-NG',
        ns: ['common', 'login'],
        defaultNS: 'common',
        backend: {
            // translation file path
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        lng: Cookies.get('i18next') || process.env[`REACT_APP_COUNTRY_LOCALE`],
        debug: false,
        detection: {
            order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie'],
            lookupCookie: 'i18next'
        },
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            useSuspense: true,
        },
    });

export default i18n;

