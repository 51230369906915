import React from 'react';
import userService from '../services/userService';
import MainContent from "./boxes/MainContent";
import PageBody from "./boxes/PageBody";
import ChangePasswordForm from "./forms/ChangePasswordForm";
import PageHeader from "./boxes/PageHeader";
import LoadingScreen from "./LoadingScreen";
import NotificationBanner from "./boxes/NotificationBanner";

class ChangePasswordComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      message:null,
      status:null,
      isLoading:false
    }
  }

  onSubmit = (values) =>{
    if(values.new_password !== values.confirm_new_password){
      this.setState({
        status:"error",
        message: "New password and Confirm new password are not the same"
      })
    }else{
      this.setState({
        isLoading:true
      });
      userService.changePassword(values)
        .then(response=>{
          this.setState({
            status:"success",
            message: response,
            isLoading:false
          })
        })
        .catch(e=>{
          this.setState({
            status:"error",
            message: e.message,
            isLoading:false
          })
        })
    }
  };

  onDismiss = () => {
    this.setState({
      status: null
    })
  }

  renderLoading = () => {
    return (
      <MainContent>
        <PageHeader title="Change Password"/>
        <PageBody>
          <LoadingScreen/>
        </PageBody>
      </MainContent>
    )
  };

  render(){

    const {isLoading, status, message} = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title="Change Password" />
        <PageBody>

          {status && <NotificationBanner
            title={status === "error" ? "Error!" : "Notification!"}
            message={message}
            onDismiss={this.onDismiss}
            type={status === "error" ? "error" : "success"}/>}

          <div className="dashboard-card">
            <ChangePasswordForm onSubmit={this.onSubmit} />
          </div>

        </PageBody>
      </MainContent>
    )
  }
}

export default ChangePasswordComponent;