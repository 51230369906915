import React from 'react';

export default function PlanResetNotice({ onNext, onBack }) {
  return (
    <>
      <div className="dashboard-card plan-expiry-notice text_center">
        <h1 className="plan-expiry-notice__header-text">
          Important!
        </h1>
        <h1 className="plan-expiry-notice__header-text">
          <span role="img" aria-label="Notice">👇</span>
        </h1>
        <p className="plan-expiry-notice__body-text">
          Your graduated benefits will be reset and you will need
          to wait 7 days to access care after payment.
        </p>
        <p className="plan-expiry-notice__body-text">
          To ensure you maintain your graduated benefits and get instant
          access to care in the future, ensure you never cancel your plan.
        </p>
        <button
          className="dashboard__primary-btn article__btn"
          onClick={onNext}
        >
          Reactivate Plan
        </button>
        &nbsp;
        &nbsp;
        <button
          className="dashboard__secondary-btn article__btn"
          onClick={onBack}>
          Back
        </button>
      </div>
    </>

  );
}