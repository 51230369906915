import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { reset } from 'redux-form';
import SignUpForm from '../components/forms/SignUpForm';
import uService from '../services/utilityService';
import LoadingScreen from '../components/LoadingScreen';
import userService from '../services/userService';
import * as userActions from '../actions/user';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';

class SignUpContainer extends React.Component {
    constructor(props) {
        super(props);
        //check match for params, if exists get enrollee type and/or hash

        const { location } = props;
        const urlParams = new URLSearchParams(location.search);
        const hash = urlParams.get('hash');
        const successful = urlParams.get('successful') === 'true';

        this.state = {
            isLoading: true,
            formData: {
                user: {
                    hash: hash || null
                }
            },
            message: null,
            error: null,
            successful
        };

        this.errorHolder = React.createRef();
    }

    componentDidMount() {
        const { history } = this.props;
        const { user } = this.state.formData;
        Promise.all([uService.getUtilities(), userService.unhashParams(user.hash)]).then(
            (response) => {
                this.setState({
                    isLoading: false,
                    formData: {
                        user: Object.assign({}, user, {
                            ...response[1],
                            enrolleeType: 1,
                            accessToken: this.props.user.credentials.accessToken
                        }),
                        utils: response[0]
                    },
                    error: null
                });
            },
            (e) => {
                if (e && e.code === 403) {
                    history.push('/dashboard/enrollees');
                } else {
                    this.setError({
                        message: e.message || 'An Error Occurred',
                        status: e.code,
                        code: e.code
                    });
                }
            }
        );
    }

    setError = (error, abort) => {
        this.setState({
            isLoading: false,
            abort,
            error
        });

        this.scrollToError();
    };

    scrollToError = () => {
        try {
            this.errorHolder.scrollIntoView({ behaviour: 'smooth' });
        } catch (e) {
            console.log('cant scroll to error');
        }
    };

    onSubmitForm = (values, dispatch) => {
        this.setState({
            isLoading: true
        });

        userService.register(values).then(
            (message) => {
                //reset sign up form on successful submission
                dispatch(reset('signUpForm'));

                if (this.props.user.email === values.email_address) {
                    dispatch(userActions.authUser(this.props.user.credentials.accessToken));
                }

                this.setState({
                    error: null,
                    message: message,
                    successful: true,
                    abort: false,
                    isLoading: false
                });
            },
            (e) => {
                this.setError({
                    message: e.message,
                    code: e.status
                });
            }
        );
    };

    render() {
        const { isLoading, formData, error, abort, successful } = this.state;

        if (isLoading)
            return (
                <SignUpFormHolder>
                    <LoadingScreen />
                </SignUpFormHolder>
            );

        if (!isLoading && abort)
            return (
                <SignUpFormHolder>
                    <div
                        style={{
                            padding: '10px',
                            margin: '10px',
                            backgroundColor: 'red',
                            color: 'white'
                        }}
                    >
                        <h3>
                            An error seems to have occurred. Please try again. If issues persist,
                            kindly send a mail to{' '}
                            <a
                                style={{ color: 'white' }}
                                href="mailto:hellonigeria@getreliancehealth.com?Subject=Enrollee Sign Up Help [from web app]"
                            >
                                hellonigeria@getreliancehealth.com
                            </a>
                            .
                        </h3>
                    </div>
                </SignUpFormHolder>
            );

        if (!isLoading && successful)
            return (
                <SignUpFormHolder>
                    <div className="dashboard-card">
                        <div>
                            <h3 className="dashboard-card__title-text">Congratulations!</h3>
                            <p className="dashboard-card__body-text">
                                Your Enrollee has successfully signed up for a RelianceHMO insurance
                                plan and can start using the plan immediately.
                                <br />
                                <br />
                                We just sent an email to the email address you provided us. Kindly
                                read the very important information carefully as they contain all
                                you need to start using your insurance.
                                <br />
                                <br />
                                If you have any issues our Reliance Help Centre agents are always
                                happy to help via:
                                <br />
                                Email:{' '}
                                <a href="mailto:hellonigeria@getreliancehealth.com?Subject=Enrollee Enquiry [from web app]">
                                    hellonigeria@getreliancehealth.com
                                </a>
                                <br />
                                Telephone: <a href="tel:070073542623">0700-7354-2623</a>
                                <br />
                                Whatsapp: <a href="tel:07035369587">017001580</a>
                                <br />
                                Facebook:{' '}
                                <a href="https://www.facebook.com/pg/reliancehmo">@reliancehmo</a>
                                <br />
                                <br />
                                Thanks for choosing RelianceHMO.
                            </p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <i className="fa fa-chevron-left" />
                                <Link
                                    to="/dashboard/enrollees"
                                    style={{
                                        textDecoration: 'none',
                                        fontSize: '1.6rem',
                                        marginLeft: '0.5rem',
                                        color: '#094063',
                                        paddingTop: '0.4rem'
                                    }}
                                >
                                    {' '}
                                    Return to dashboard
                                </Link>
                            </div>
                        </div>
                    </div>
                </SignUpFormHolder>
            );

        return (
            <SignUpFormHolder user={formData.user}>
                {!isLoading && error && (
                    <div
                        ref={(el) => (this.errorHolder = el)}
                        className="alert__banner error"
                        style={{ marginBottom: '2rem' }}
                    >
                        <h3>{error.message}</h3>
                    </div>
                )}
                <div className="dashboard-card">
                    <SignUpForm
                        data={formData.utils}
                        user={formData.user}
                        onSubmit={this.onSubmitForm}
                        onError={this.setError}
                    />
                </div>
            </SignUpFormHolder>
        );
    }
}

export default withRouter(SignUpContainer);

export const SignUpFormHolder = (props) => (
    <MainContent>
        <PageHeader title="Enrollee Sign-Up Form" />
        <PageBody>{props.children}</PageBody>
    </MainContent>
);
