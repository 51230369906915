import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, change, getFormSyncErrors } from 'redux-form';

const BoldFormLabel = ({ children, style }) => {
    return (
        <h3
            style={{
                fontWeight: 700,
                // fontSize: '4rem',
                marginBottom: '16px',
                ...style
            }}
        >
            {children}
        </h3>
    );
};

// const Required = () => <span style={{ color: '#AB0404' }}>*</span>;

const HelperText = ({ children }) => {
    return <p style={{ fontSize: '14px', color: '#AB0404' }}>{children}</p>;
};

class AddSingleEnrolleeForm extends React.Component {
    constructor(props) {
        super(props);

        let currentStep = 0;

        this.state = {
            currentStep, // Initial form step
            lastStep: 0,
            touched: {
                product_id: false,
                for_me: false,
                can_complete_profile: false,
                can_manage: false,
                first_name: false,
                last_name: false,
                phone_number: false,
                email_address: false
            }
        };
    }

    toggleForMe = (for_me) => {
        const { user, dispatch } = this.props;

        if (!for_me) {
            return;
        }

        if (for_me === '1') {
            dispatch(change('addSingleEnrolleeForm', 'first_name', user.firstName));
            dispatch(change('addSingleEnrolleeForm', 'last_name', user.lastName));
            dispatch(change('addSingleEnrolleeForm', 'email_address', user.email));
            dispatch(change('addSingleEnrolleeForm', 'phone_number', user.phoneNumber));
            dispatch(change('addSingleEnrolleeForm', 'can_manage', '1'));
            // dispatch(change('addSingleEnrolleeForm', 'can_complete_profile', '1'));
        } else {
            dispatch(change('addSingleEnrolleeForm', 'first_name', ''));
            dispatch(change('addSingleEnrolleeForm', 'last_name', ''));
            dispatch(change('addSingleEnrolleeForm', 'email_address', ''));
            dispatch(change('addSingleEnrolleeForm', 'phone_number', ''));
            dispatch(change('addSingleEnrolleeForm', 'can_manage', ''));
            // dispatch(change('addSingleEnrolleeForm', 'can_complete_profile', ''));
        }
    };

    onDismiss = () => {
        this.setState({
            status: ''
        });
    };

    render() {
        const { handleSubmit, pristine, submitting, invoicePlans, formErrors, formValues } =
            this.props;

        return (
            <form
                className="dashboard-form"
                onSubmit={(e) => {
                    e.preventDefault();

                    if (this.state.currentStep === 0) {
                        this.setState({
                            touched: { ...this.state.touched, product_id: true, for_me: true }
                        });
                    }

                    if (this.state.currentStep === 1) {
                        this.setState({
                            touched: {
                                ...this.state.touched,
                                can_manage: true,
                                first_name: true,
                                last_name: true,
                                phone_number: true,
                                email_address: true
                            }
                        });

                        if (
                            !formValues?.can_manage ||
                            !formValues?.first_name?.trim() ||
                            !formValues?.last_name?.trim() ||
                            !formValues?.phone_number?.trim() ||
                            !formValues?.email_address?.trim()
                        ) {
                            this.props.setNotification(
                                'Kindly fill all the required fields before proceeding',
                                'error'
                            );

                            return;
                        }
                    }

                    if (formValues?.for_me === '0' && this.state.currentStep === 0) {
                        this.setState({
                            currentStep: 1
                        });

                        return;
                    }

                    handleSubmit(e);
                }}
            >
                <div className="dashboard-form-item-wrapper">
                    {this.state.currentStep === 0 && (
                        <div className="dashboard-input-wrap profile-input-wrap">
                            <span className="dashboard-form-item">
                                <label htmlFor="" className="profile-item__label">
                                    <BoldFormLabel>
                                        Select Health Plan
                                        {/* <Required /> */}
                                    </BoldFormLabel>
                                    Select a Plan for the Beneficiary
                                </label>
                                <Field
                                    component="select"
                                    className="dashboard-form__input dashboard-form__select"
                                    name="product_id"
                                    required
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        this.setState({
                                            touched: { ...this.state.touched, product_id: true }
                                        });

                                        this.props.dispatch(
                                            this.props.change(
                                                'addSingleEnrolleeForm',
                                                'product_id',
                                                value
                                            )
                                        );
                                    }}
                                >
                                    <option value="">--Select--</option>
                                    {invoicePlans
                                        .filter((product) => product.no_of_available_slots > 0)
                                        .map((product) => (
                                            <option key={product.id} value={product.id}>
                                                {product.name}
                                            </option>
                                        ))}
                                </Field>

                                {formErrors?.product_id && this.state.touched?.product_id && (
                                    <HelperText>{formErrors.product_id}</HelperText>
                                )}
                            </span>

                            <span className="dashboard-form-item">
                                <label htmlFor="" className="profile-item__label">
                                    <BoldFormLabel>
                                        Is this plan for you?
                                        {/* <Required /> */}
                                    </BoldFormLabel>
                                </label>
                                <span
                                    className="dashboard-form__checklist-wrap"
                                    style={{ flexDirection: 'row', columnGap: '40px' }}
                                >
                                    <span className="dashboard-form-item__radio-check-btn">
                                        <Field
                                            name="for_me"
                                            component="input"
                                            type="radio"
                                            value="1"
                                            id="yes"
                                            onChange={(e) => {
                                                this.setState({
                                                    touched: {
                                                        ...this.state.touched,
                                                        for_me: true
                                                    }
                                                });

                                                this.toggleForMe(e.target.value);
                                            }}
                                        />
                                        <label htmlFor="yes">Yes</label>
                                    </span>

                                    <span className="dashboard-form-item__radio-check-btn">
                                        <Field
                                            name="for_me"
                                            component="input"
                                            type="radio"
                                            value="0"
                                            id="no"
                                            onChange={(e) => {
                                                this.setState({
                                                    touched: {
                                                        ...this.state.touched,
                                                        for_me: true
                                                    }
                                                });

                                                this.toggleForMe(e.target.value);
                                            }}
                                        />
                                        <label htmlFor="no">No</label>
                                    </span>
                                </span>
                                {formErrors?.for_me && this.state.touched?.for_me && (
                                    <HelperText>{formErrors.for_me}</HelperText>
                                )}
                            </span>
                        </div>
                    )}

                    {this.state.currentStep === 1 && (
                        <div className="dashboard-input-wrap profile-input-wrap">
                            <span className="dashboard-form-item" style={{ marginTop: '0' }}>
                                <label className="profile-item__label">
                                    <BoldFormLabel>
                                        Do you want to complete this person’s profile?
                                    </BoldFormLabel>
                                </label>

                                <p
                                    className="dashboard-form__small-text"
                                    style={{ maxWidth: '420px', padding: 0, fontWeight: 500 }}
                                >
                                    You will need their date of birth, home address, and picture to
                                    do this. Otherwise, we will send them an email with the form
                                </p>

                                <span
                                    className="dashboard-form__checklist-wrap"
                                    style={{ flexDirection: 'row', columnGap: '40px' }}
                                >
                                    <span className="dashboard-form-item__radio-check-btn">
                                        <Field
                                            id="yes_can_manage"
                                            name="can_manage"
                                            component="input"
                                            type="radio"
                                            value={'1'}
                                            onChange={(e) => {
                                                this.setState({
                                                    touched: {
                                                        ...this.state.touched,
                                                        can_manage: true
                                                    }
                                                });
                                            }}
                                        />
                                        <label htmlFor="yes_can_manage">Yes</label>
                                    </span>

                                    <span className="dashboard-form-item__radio-check-btn">
                                        <Field
                                            id="no_can_manage"
                                            name="can_manage"
                                            component="input"
                                            type="radio"
                                            value={'0'}
                                            onChange={(e) => {
                                                this.setState({
                                                    touched: {
                                                        ...this.state.touched,
                                                        can_manage: true
                                                    }
                                                });
                                            }}
                                        />
                                        <label htmlFor="no_can_manage">No</label>
                                    </span>
                                </span>
                                {formErrors?.can_manage && this.state.touched?.can_manage && (
                                    <HelperText>{formErrors.can_manage}</HelperText>
                                )}
                            </span>

                            {/* <span className="dashboard-form-item" style={{ marginTop: '16px' }}>
                                <label className="profile-item__label">
                                    <BoldFormLabel style={{ maxWidth: '450px' }}>
                                        Do you want to have this person’s digital ID on your
                                        smartphone?
                                    </BoldFormLabel>
                                </label>

                                <p
                                    className="dashboard-form__small-text"
                                    style={{ maxWidth: '450px', padding: 0, fontWeight: 500 }}
                                >
                                    Great for children and elderly parents without smartphones
                                </p>
                                <span className="dashboard-form__checklist-wrap">
                                    <span className="dashboard-form-item__radio-check-btn">
                                        <Field
                                            id="yes_can_manage"
                                            name="can_manage"
                                            component="input"
                                            type="radio"
                                            value={'1'}
                                            onChange={(e) => {
                                                this.setState({
                                                    touched: {
                                                        ...this.state.touched,
                                                        can_manage: true
                                                    }
                                                });
                                            }}
                                            required
                                            disabled
                                        />
                                        <label htmlFor="yes_can_manage">Yes</label>
                                    </span>

                                    <span className="dashboard-form-item__radio-check-btn">
                                        <Field
                                            id="no_can_manage"
                                            name="can_manage"
                                            component="input"
                                            type="radio"
                                            value={'0'}
                                            onChange={(e) => {
                                                this.setState({
                                                    touched: {
                                                        ...this.state.touched,
                                                        can_manage: true
                                                    }
                                                });
                                            }}
                                            disabled
                                        />
                                        <label htmlFor="no_can_manage">No</label>
                                    </span>
                                </span>
                                {formErrors?.can_manage && this.state.touched?.can_manage && (
                                    <HelperText>{formErrors.can_manage}</HelperText>
                                )}
                            </span> */}

                            <span className="dashboard-form-item" style={{ marginTop: '16px' }}>
                                <label className="profile-item__label">
                                    <BoldFormLabel style={{ maxWidth: '450px' }}>
                                        Beneficiary Details
                                    </BoldFormLabel>
                                </label>

                                <div style={{ display: 'flex', gap: '16px' }}>
                                    <span className="dashboard-form-item">
                                        <label className="profile-item__label" htmlFor="">
                                            Firstname
                                        </label>
                                        <Field
                                            component="input"
                                            className="dashboard-form__input"
                                            type="text"
                                            name="first_name"
                                            placeholder=""
                                            required={this.currentStep === 1}
                                        />
                                    </span>
                                    <span className="dashboard-form-item">
                                        <label className="profile-item__label" htmlFor="">
                                            Lastname
                                        </label>
                                        <Field
                                            component="input"
                                            className="dashboard-form__input"
                                            type="text"
                                            name="last_name"
                                            placeholder=""
                                            required={this.currentStep === 1}
                                        />
                                    </span>
                                </div>

                                <div style={{ display: 'flex', gap: '16px' }}>
                                    <span className="dashboard-form-item">
                                        <label className="profile-item__label" htmlFor="">
                                            Email address
                                        </label>
                                        <Field
                                            component="input"
                                            className="dashboard-form__input"
                                            type="email"
                                            name="email_address"
                                            placeholder=""
                                            required={this.currentStep === 1}
                                        />
                                    </span>

                                    <span className="dashboard-form-item">
                                        <label className="profile-item__label" htmlFor="">
                                            Phone Number
                                        </label>
                                        <Field
                                            component="input"
                                            className="dashboard-form__input"
                                            type="number"
                                            name="phone_number"
                                            placeholder=""
                                            required={this.currentStep === 1}
                                        />
                                    </span>
                                </div>
                            </span>
                        </div>
                    )}

                    <Field
                        className="hidden"
                        name="access_token"
                        component="input"
                        style={{ display: 'none' }}
                    />

                    {/* {this.state.currentStep === 1 && (
                        <div className="dashboard-input-wrap profile-input-wrap">
                            <span className="dashboard-form-item">
                                <label className="profile-item__label" htmlFor="">
                                    Firstname
                                </label>
                                <Field
                                    component="input"
                                    className="dashboard-form__input"
                                    type="text"
                                    name="first_name"
                                    placeholder=""
                                    readOnly={formValues.for_me === '1'}
                                />
                            </span>
                            <span className="dashboard-form-item">
                                <label className="profile-item__label" htmlFor="">
                                    Lastname
                                </label>
                                <Field
                                    component="input"
                                    className="dashboard-form__input"
                                    type="text"
                                    name="last_name"
                                    placeholder=""
                                    readOnly={formValues.for_me === '1'}
                                />
                            </span>
                        </div>
                    )} */}

                    {/* {this.state.currentStep === 2 && (
                        <div className="dashboard-input-wrap profile-input-wrap ">
                            <span className="dashboard-form-item">
                                <label className="profile-item__label" htmlFor="">
                                    Phone Number
                                </label>
                                <Field
                                    component="input"
                                    className="dashboard-form__input"
                                    type="number"
                                    name="phone_number"
                                    placeholder=""
                                    readOnly={formValues.for_me === '1'}
                                />
                            </span>
                            <span className="dashboard-form-item">
                                <label className="profile-item__label" htmlFor="">
                                    {formValues.for_me === '1'
                                        ? 'Email Address'
                                        : 'Email Address (Leave blank, if unavailable)'}
                                </label>
                                <Field
                                    component="input"
                                    className="dashboard-form__input"
                                    type="email"
                                    name="email_address"
                                    placeholder=""
                                    readOnly={formValues.for_me === '1'}
                                />
                            </span>
                        </div>
                    )} */}

                    {/*  */}

                    {/* <div className="dashboard-input-wrap profile-input-wrap "> */}
                    {/* <span className="dashboard-form-item">
                            <label className="profile-item__label" htmlFor="">
                                Phone Number
                            </label>
                            <Field
                                component="input"
                                className="dashboard-form__input"
                                type="number"
                                name="phone_number"
                                placeholder=""
                                readOnly={forMe === '1'}
                            />
                        </span> */}

                    {/* {extensions.length > 0 ? (
                            <span className="dashboard-form-item">
                                <label className="profile-item__label" htmlFor="">
                                    Add Extra Benefit(s)
                                </label>
                                {selectedPlan ? (
                                    <CheckboxGroup
                                        options={extensions.filter(
                                            (ext) =>
                                                ext.is_available &&
                                                ext.description.indexOf(selectedPlan.name) > -1
                                        )}
                                        name="extensions"
                                    />
                                ) : (
                                    <span className="dashboard-form__checklist-wrap">
                                        <p style={{ color: '#6d6d6d' }}>Please select a plan.</p>
                                    </span>
                                )}
                            </span>
                        ) : (
                            <span className="dashboard-form-item">
                                <span className="dashboard-form__checklist-wrap">
                                    <p style={{ color: '#6d6d6d' }}>
                                        You have not purchased any extra benefit.
                                    </p>
                                </span>
                            </span>
                        )} */}

                    {/* <span className="dashboard-form-item" style={{ marginTop: '0' }}>
                            <br />
                            <span
                                className="dashboard-form__checklist-wrap dashboard-form-item__radio-check-btn dashboard-form-item__check-btn"
                                style={{ display: 'block' }}
                            >
                                <Field
                                    name="can_complete_profile"
                                    id="can_complete_profile"
                                    component="input"
                                    type="checkbox"
                                    disabled={forMe === '1'}
                                />
                                <label htmlFor="can_complete_profile">
                                    <p style={{ width: '82%' }}>
                                        Do you want to complete this person’s profile?
                                    </p>
                                </label>
                                <p className="dashboard-form__small-text">
                                    You will need their date of birth, home address, and picture to
                                    do this.
                                    <br />
                                    Otherwise, we will send them an email with the form
                                </p>
                            </span>
                        </span> */}

                    {/* <span className="dashboard-form-item" style={{ marginTop: '0' }}>
                            <br />
                            <span
                                className="dashboard-form__checklist-wrap dashboard-form-item__radio-check-btn dashboard-form-item__check-btn"
                                style={{ display: 'block' }}
                            >
                                <Field
                                    name="can_manage"
                                    id="can_manage"
                                    component="input"
                                    type="checkbox"
                                    disabled={forMe === '1'}
                                />
                                <label htmlFor="can_manage">
                                    <p style={{ width: '82%' }}>
                                        Do you want to have this person’s digital ID on your
                                        smartphone ?
                                    </p>
                                </label>
                                <p className="dashboard-form__small-text">
                                    Great for children and elderly parents without smartphones
                                </p>
                            </span>
                        </span> */}
                    {/* </div> */}
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: this.state.currentStep === 0 ? 'flex-end' : 'space-between'
                    }}
                >
                    <button
                        className="dashboard__primary-btn dashboard-form__btn"
                        disabled={pristine || submitting}
                        type={'submit'}
                    >
                        {'Proceed'}
                    </button>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    const { pristine } = props; // Access state from props

    if (pristine) {
        return errors;
    }

    if (!values.product_id) {
        errors.product_id = 'Please select a plan';
    }

    if (!values.for_me) {
        errors.for_me = 'Please select an option';
    }

    if (!values.can_complete_profile) {
        errors.can_complete_profile = 'Please select an option';
    }

    if (!values.can_manage) {
        errors.can_manage = 'Please select an option';
    }

    if (!values.first_name) {
        errors.first_name = 'Please enter a first name';
    }

    if (!values.last_name) {
        errors.last_name = 'Please enter a last name';
    }

    if (!values.phone_number) {
        errors.phone_number = 'Please enter a phone number';
    }

    if (!values.email_address) {
        errors.email_address = 'Please enter an email address';
    }

    return errors;
};

AddSingleEnrolleeForm = reduxForm({
    form: 'addSingleEnrolleeForm',
    destroyOnUnmount: false,
    validate
})(AddSingleEnrolleeForm);

export const singleFormSelector = formValueSelector('addSingleEnrolleeForm');

export const singleEnrolleeFields = [
    'access_token',
    'product_id',
    'for_me',
    'first_name',
    'last_name',
    'phone_number',
    'email_address',
    // 'can_complete_profile',
    'can_manage'
];

const mapStateToProps = (state) => {
    return {
        initialValues: {
            access_token: state.session.user.credentials.accessToken
            // can_manage: '1'
        },
        formValues: singleFormSelector(state, ...singleEnrolleeFields),
        formErrors: getFormSyncErrors('addSingleEnrolleeForm')(state)
    };
};

AddSingleEnrolleeForm = connect(mapStateToProps)(AddSingleEnrolleeForm);

export default withRouter(AddSingleEnrolleeForm);
