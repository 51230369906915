import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'querystring';
import { logoutUser } from '../actions/user';
import LoadingScreen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';

const LogoutContainer = ({location, history}) => {

    const from = queryString.parse(location.search.substr(1)).r;

    logoutUser(from, history);

    return (
      <MainContent>
          <LoadingScreen/>
      </MainContent>
    )
};

const mapDispatch = (dispatch, ownProps) => ({
    logout: (from) => {
        dispatch(logoutUser(from, ownProps.history));
    }
});

export default withRouter(connect(null, mapDispatch)(LogoutContainer));