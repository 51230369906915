import React from 'react';
import Modal from "./boxes/Modal";

const NoAccessModal= ({redirectUrl}) => {
  return (
    <Modal title="Notification" onClose={()=>window.location.replace(redirectUrl)} open={true}>
      <div style={{display:"flex",flexDirection: "column",padding:"1rem"}}>
        <p style={{fontSize:"2rem", paddingBottom:"1em"}}>Sorry, but you do not have permission to view this page.</p>
        <button className="dashboard__primary-btn" onClick={()=>window.location.replace(redirectUrl)}>
          Click here to go back
        </button>
      </div>
    </Modal>
  )
};

export default NoAccessModal;
