import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LOGO from '../img/rh-new-logo.svg';
import { getEnrolleeDashboardUrl } from '../services/utilityService';
import SidebarMenuItem from '../components/SidebarMenuItemComponent';
import withFeatureFlags from '../components/utils/FeatureFlagWrapper';

class SidebarContainer extends React.Component {
    render() {
        const {
            showSideBar,
            userName,
            isEnrollee,
            flags: { manageEnrollees, manageBilling }
        } = this.props;
        let enrolleeDashboardUrl = getEnrolleeDashboardUrl();

        const items = [];

        items.push({
            path: '/dashboard/home',
            icon: 'fas fa-home',
            title: 'Home'
        });

        if (manageEnrollees) {
            items.push({ path: '/dashboard/enrollees', icon: 'fas fa-users', title: 'Enrollees' });
        }

        if (manageBilling) {
            items.push({
                path: '/dashboard/invoices',
                icon: 'fas fa-file-text',
                title: 'Billing'
            });
        }

        items.push(
            {
                path: '/dashboard/change-password',
                icon: 'fas fa-lock',
                title: 'Change Password'
            },
            {
                path: '/dashboard/help',
                icon: 'fas fa-question-circle',
                title: 'Help'
            }
        );

        return (
            <div id="left" className={`column sidebar ${showSideBar ? '' : 'active'}`}>
                <div className="top-left">
                    <div className="sidebar__top sidebar__top--accounts">
                        <p>Welcome</p>
                        <p>{userName.toUpperCase() || 'USER'}</p>
                    </div>
                </div>
                <div className="bottom sidebar--space-between sidebar--accounts">
                    <nav>
                        <ul>
                            {isEnrollee && (
                                <li className="dashboard__menu">
                                    <i className="fas fa-user" />
                                    <a href={enrolleeDashboardUrl}>My Profile</a>
                                </li>
                            )}
                            {items.map((item, i) => (
                                <SidebarMenuItem
                                    key={i}
                                    to={item.path}
                                    icon={item.icon}
                                    title={item.title}
                                />
                            ))}
                            <li className="dashboard__menu">
                                <i className="fas fa-sign-out-alt" aria-hidden="true"></i>
                                <a href="/logout">Log Out</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="sidebar__bottom">
                        <img src={LOGO} alt="RelianceHMO's Logo" />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { showSideBar } = state.display || {};
    return { showSideBar };
}

export default withRouter(connect(mapStateToProps)(withFeatureFlags(SidebarContainer)));
