import React from 'react';
import PropTypes from 'prop-types';

const NotificationBanner = (props) => {
  return (
    <div className={`alert__banner ${props.type}`} style={{marginBottom: "2rem"}}>
      <div className="message">
        <h3>{props.title}</h3>
        <p>{props.message}</p>
      </div>
      <span className="dismiss" onClick={props.onDismiss}>
        <i className="fa fa-times fa-2x"/>
      </span>

    </div>
  )
};

NotificationBanner.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  onDismiss: PropTypes.func
};

export default NotificationBanner;