import React from 'react';
import InvoiceDetailsComponent from './InvoiceDetailsComponent';

export default function SelectExpiredReason({ invoice, duration, reasons, onSelect }) {
  return (
    <>
      <div className="dashboard-card plan-expiry-notice">
        <h1 className="plan-expiry-notice__header-text">Your plan has been deactivated</h1>

        <InvoiceDetailsComponent
          invoice={invoice}
          isSummary
        />

        <p className="plan-expiry-notice__body-text">
          Your plan expired {duration} days ago, let us know why you are reactivating it?
        </p>

        {
          reasons.map((option, key) => (
            <button key={key} className="dashboard__secondary-btn--full-width" onClick={() => { onSelect(option.id) }}>
              {option.name}
            </button>
          ))
        }
      </div>
    </>
  );
}