import React from 'react';
import { withRouter } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import MainContent from '../components/boxes/MainContent';

class HomePageContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    renderLoading() {
        return (
            <MainContent>
                <PageHeader title="" />
                <PageBody>
                    <LoadingScreen />
                </PageBody>
            </MainContent>
        );
    }

    render() {
        return (
            <MainContent>
                <PageHeader title="Home" />
                <PageBody>
                    <h3 className="dashboard-card__title-text">Home page</h3>
                </PageBody>
            </MainContent>
        );
    }
}

export default withRouter(HomePageContainer);
