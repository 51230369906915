import React from  'react';
import PropTypes from 'prop-types';
import numberFormatter from 'number-formatter';
import Modal from '../boxes/Modal';

const VariableBillModal = ({amount,total,duration,onClose,onPay,onRefund,onDismiss, open}) => {

  return (
    <Modal title="Attention!" onClose={onClose} open={open}>
      <div className="variable_bill_notice" style={{fontSize: "1.2em", lineHeight: "1.6em"}}>
        <p style={{fontSize: "1.2em"}}>We noticed you had a previous plan that you stopped paying for.
          We will need you to balance outstanding payments before you will be
          able to allocate the new plan you purchased to an enrollee.
        </p>
        <br/>
        <p style={{fontSize: "1.2em", marginBottom: "0.5em"}}>Here are the details of your new payment:</p>

        <table className="dashboard-table">
        <thead>
        <tr>
          <th className="dashboard-table-item dashboard-table-item__header">Monthly Payment</th>
          <th className="dashboard-table-item dashboard-table-item__header">Payment Period</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td className="dashboard-table-item dashboard-table-item__body">&#8358; {`${numberFormatter("#,###.00",amount)}`}</td>
            <td className="dashboard-table-item dashboard-table-item__body">{duration} Months</td>
          </tr>
        </tbody>
      </table>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "1.5rem 2rem",
        alignItems: "center"
      }}>
        <h2 style={{fontSize: "1.5rem"}}>Total Outstanding Bill</h2>
        <h3 style={{fontSize: "2rem"}}>
          &#8358; {`${numberFormatter("#,###.00",total)}`}
        </h3>
      </div>

        <p style={{fontSize: "1.2em", marginBottom: "0.5em"}}>
          Click on a button below to perform an action
        </p>
        <p>
          If you click, "Pay" you will be taken to a page where you can make your first outstanding payment.
          Subsequent payments would be billed alongside your base insurance bill according the payment frequency you selected.
        </p>
        <p>
          If you click, "Refund", we will refund the money for the plan to you. But please note you won't be able to purchase
          any other plan until you complete your outstanding payments.
        </p>
        <br/>
        <p style={{fontSize: "1.2em", marginBottom: "0.5em"}}> Our support team is happy to answer any questions or enquiries you may have. You can reach them on: </p>
        <p>
          Email: <a href="mailto:hellonigeria@getreliancehealth.com">hellonigeria@getreliancehealth.com</a>
          <br/>
          Phone: <a href="tel:0700-73542623">0700-73542623</a>
        </p>

        <div className="action_wrapper" style={{marginTop: "1.6em"}}>
          <button className="pay dashboard__secondary-btn__green" style={{margin: "0.3em"}} onClick={onPay}>Pay</button>
          <button className="refund dashboard__secondary-btn__danger" style={{margin: "0.3em"}} onClick={onRefund}>Cancel and Refund</button>
          <button className="dismiss dashboard__secondary-btn" style={{margin: "0.3em"}} onClick={onDismiss}>Dismiss</button>
        </div>

      </div>
    </Modal>
  )
};

VariableBillModal.propTypes = {
  amount: PropTypes.number,
  total: PropTypes.number,
  duration: PropTypes.number,
  onClose: PropTypes.func,
  onPay: PropTypes.func,
  onRefund: PropTypes.func,
  onDismiss: PropTypes.func,
  open: PropTypes.bool
};


export default VariableBillModal;