import React from 'react';
import numberFormatter from 'number-formatter';

const isExtensionAdded = (extArray, _extId) => {
  return extArray.filter((ext) => ext.id === _extId).length !== 0;
};

export default class ExtensionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedFrequency: "monthly"
    }
  }

  addExtension = () => {
    const {onAdd, extension} = this.props;
    onAdd({
        id: extension.id,
        name: extension.name,
        code: extension.extension.code,        
        price: extension.price        
      });
  };

  removeExtension = () => {
    const {onRemove, extension} = this.props;
    onRemove({
      id: extension.id
    })
  };

  dismissError = () => {
    this.setState({
      error: null
    })
  };

  getExtensionContent = () => {       
    const code = this.props.extension.extension ? this.props.extension.extension.code.toLowerCase() : null;
    if(code === "pec"){
      return (
        <p className="dashboard-card__body-text">
          Pre-existing conditions are SERIOUS illnesses that have existed AT ANY TIME BEFORE the start of 
          your insurance plan.
          <br/>
          <br/>
          With this pre-existing condition coverage, treatment, tests and drugs for selected conditions 
          like Hypertension, Diabetes, Arthritis, and peptic ulcer are covered from the moment you buy the 
          plan.
          <br/>
          <br/>
          This is perfect for older parents, senior citizens or anyone battling with any of these 
          conditions. You can visit the
          <a className="link-item" href="https://reliancehmo.com/faq" target="_blank" rel="noopener noreferrer"> Pre-existing condition section</a> of
          the FAQs for more details 
          <br/>
          <br/>
          Click <a href="https://reliancehmo.com/pec-benefits" className="link-item" target="_blank" rel="noopener noreferrer">here</a> to view a comprehensive list of
          benefits for the Pre-existing Condition Plan
        </p>
      )
    }else{
      return (
        <p className="dashboard-card__body-text">
          For further details and enquires about this benefit, please contact our support team on <a href="mailto:hellonigeria@getreliancehealth.com">hellonigeria@getreliancehealth.com</a>
        </p>
      )
    }
  }

  render() {

    const {extension, isActive, addedExtensions} = this.props;    
    const{error, selectedFrequency} = this.state;
    return (
      <div className={`${isActive ? "" : "hidden"}`}>
        <div className="mdl-details__content">
          {error &&
            <div className="red" style={{
              padding: "1rem",
              color: "white",
              borderRadius: "4px",
              fontSize: "1.2rem",
              margin: "1rem 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <p>{error}</p>
              <span style={{fontSize: "1.5rem", fontWeight: "bolder", color: "white", cursor: "pointer"}} onClick={this.dismissError}>
                  &times;
              </span>
            </div>
          }
          
          {this.getExtensionContent()}
         
          <div style={{marginBottom: "2rem"}}>
            <span className="dashboard-card__body-text">Price: </span>
            <span className="plan-pricetag">&#8358;{numberFormatter("#,###.00", (extension.price[selectedFrequency]))}</span>
            <span className="dashboard-card__body-text"> {selectedFrequency}</span>
          </div>          
        </div>
        
        {isExtensionAdded(addedExtensions, extension.id) ? (
          <button
            className="dashboard__primary-btn dashboard-form__btn"
            style={{background: "#AB0404"}}
            onClick={this.removeExtension}>
            Remove Benefit
          </button>
        ) : (
          <button
            className="dashboard__primary-btn dashboard-form__btn"
            onClick={this.addExtension}>
            Add Benefit
          </button>
        )}
      </div>
    )
  }
}
