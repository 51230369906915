const locales = {
    "EG": {
        "ar": { "language": "Arabic" },
        "en": { "language": "English" },
        "fr": { "language": "French" }
    },
    "NG": {
        "ar": { "language": "Arabic" },
        "en": { "language": "English" },
        "fr": { "language": "French" }
    },
    "SN": {
        "ar": { "language": "Arabic" },
        "en": { "language": "English" },
        "fr": { "language": "French" }
    }
};

export default locales;

