import { combineReducers } from 'redux';
import enrolleeReducer from './enrollee';
import invoiceReducer from './invoice';
import userReducer from './user';
import displayReducer from './display';
import languageReducer from './language';
import {reducer as formReducer} from 'redux-form';
import { sessionReducer } from 'redux-react-session';

const rootReducer = combineReducers({
    enrollees:enrolleeReducer,
    invoice:invoiceReducer,
    user:userReducer,
    form:formReducer,
    session:sessionReducer,
    display:displayReducer,
    language:languageReducer
});

export default rootReducer;
