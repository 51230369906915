import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { decodeResourceStatus, decodePaymentFrequency } from '../../services/utilityService';

const EnrolleeExtensionsListComponent = ({extensions, onRemove}) => (
    <table className="dashboard-table">
      <thead>
        <tr>
          <th className="dashboard-table-item dashboard-table-item__header">Name</th>
          <th className="dashboard-table-item dashboard-table-item__header">Payment Frequency</th>
          <th className="dashboard-table-item dashboard-table-item__header">Start Date</th>
          <th className="dashboard-table-item dashboard-table-item__header">End Date</th>
          <th className="dashboard-table-item dashboard-table-item__header">Status</th>
        </tr>
      </thead>
      <tbody>
        {extensions.map(extension=>(
          <tr key={extension.id}>
            <td className="dashboard-table-item dashboard-table-item__body">{extension.product.name}</td>
            <td className="dashboard-table-item dashboard-table-item__body">{decodePaymentFrequency(extension.payment_frequency_id)}</td>
            <td className="dashboard-table-item dashboard-table-item__body">{moment(extension.cover_start_date).format("DD/MM/YYYY")}</td>
            <td className="dashboard-table-item dashboard-table-item__body">{moment(extension.cover_end_date).format("DD/MM/YYYY")}</td>
            <td className="dashboard-table-item dashboard-table-item__body" style={{textTransform: "capitalize"}}>{decodeResourceStatus(extension.active_status_id)}</td>
          </tr>
        ))}
      </tbody>
    </table>
);

EnrolleeExtensionsListComponent.propTypes = {
    extensions: PropTypes.array
};

export default EnrolleeExtensionsListComponent;