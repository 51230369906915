import { INVOICE_ACTION_TYPES } from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    isFetching:false,
    invoices:[],
    selectedInvoice:{
        details:{},
        payments:[]
    },
    error:{
        code:0,
        message:""
    },
    alert:""
});

export default function invoiceReducer(state=initialState, action){
    switch (action.type){
        case INVOICE_ACTION_TYPES.GET_INVOICES:
        case INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE:
            return Immutable(state).merge({
                isFetching: true
            });
        case INVOICE_ACTION_TYPES.GET_INVOICES_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                invoices: [...action.data]
            },{deep: true});
        case INVOICE_ACTION_TYPES.GET_INVOICES_FAIL:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            });
        case INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE_SUCCESS:
            return Immutable(state).merge({
                isFetching: false,
                selectedInvoice: action.data
            });
        case INVOICE_ACTION_TYPES.GET_INVOICE_BY_REF_CODE_FAIl:
            return Immutable(state).merge({
                isFetching: false,
                error: action.data
            });
        default:
            return state;
    }
}

