import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../boxes/Modal';

const ChangePlanModal = ({ title, isVisible, onYes, content, onClose }) => {
    return (
        <Modal title={title} onClose={onClose} open={isVisible} modalWidth={'400px'}>
            <div>
                <p style={{ fontSize: '1.4rem', lineHeight: 1.25 }}>{content}</p>

                <div
                    className="button-header"
                    style={{ marginTop: '16px', display: 'flex', columnGap: '16px' }}
                >
                    <button
                        className="dashboard__primary-btn"
                        style={{
                            backgroundColor: 'white',
                            color: '#36383c',
                            border: '1px solid #F1F2F2',
                            flex: 1,
                            borderRadius: '20px'
                        }}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="dashboard__primary-btn"
                        style={{
                            borderRadius: '20px',
                            flex: 1
                        }}
                        onClick={onYes}
                    >
                        Yes, switch
                    </button>
                </div>
            </div>
        </Modal>
    );
};

ChangePlanModal.propTypes = {
    onYes: PropTypes.func,
    onClose: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
    isVisible: PropTypes.bool
};

export default ChangePlanModal;
