import React from 'react';

export default function EnrolleeRenewalList({enrollees, isNewPurchase, onEnrolleeToggle, onEnrolleeChangePlan, plans}) {
  return( 
    <>
      {
        enrollees.map((enrollee, key) => (
          <div className="enrollee-list__item" key={key}>
            <div className="form">
              <span style={{width: "100%"}} className="dashboard-form-item__radio-check-btn">
                <input
                  type="checkbox"
                  id={`enrollee-${key}`}
                  name={`enrollee-${key}`}
                  value={enrollee.isSelected}
                  checked={enrollee.isSelected}
                  onChange={ () => onEnrolleeToggle(enrollee.user_id) }
                  required
                />
                <label htmlFor={`enrollee-${key}`}>{`${enrollee.first_name} ${enrollee.last_name} [₦${enrollee.product.price}]`}</label>
              </span>
            </div>
            <details>
              <summary className="details-summary">See more</summary>
              <div>
                <p className="dashboard-card__body-text">
                  <b>Email: </b>{`${enrollee.email}`}
                </p>
                {
                  isNewPurchase ?
                  <>
                    <p className="dashboard-card__body-text">
                      <b>Product: </b>
                      <select className="dashboard-form__input dashboard-form__select" name="enrollee_product" onChange={ (e) => onEnrolleeChangePlan(enrollee.user_id, e.target.value) }>
                        <option value="" disabled selected hidden>{enrollee.product.name}</option>
                        {plans.map((plan,key) => (
                          <option key={key} value={key}>{plan.name}</option>
                        ))}
                      </select>
                    </p>
                  </>
                  :
                  <p className="dashboard-card__body-text">
                    <b>Product: </b>{enrollee.product.name}
                  </p>
                }
                <p className="dashboard-card__body-text">
                  <b>Plan type: </b>{enrollee.product.plan_type}</p>
              </div>
            </details>
          </div>
        ))
      }
    </>
  );
}