import React from 'react';
import {reduxForm, Field} from 'redux-form';

let VerifyOTPForm = (props) => {
	const {handleSubmit,pristine,submitting} = props;
	return (
		<div className="form" style={{height: "50%"}}>
			<p style={{"marginTop": "10px"}}>
				{props.title || "A 6-digit PIN has been sent to your phone number"}
				<br/>
				Please Enter the code below
			</p>
			<form onSubmit={handleSubmit}>
				<Field component="input" type="text" minLength="6" maxLength="6" name="code" placeholder="6-Digit PIN" required/>
				<input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value="Submit" />
			</form>
		</div>
	)
};


VerifyOTPForm = reduxForm({
    form:'verifyOTPForm'
})(VerifyOTPForm);

export default VerifyOTPForm;
