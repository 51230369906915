import { LANGUAGE_ACTION_TYPES } from '../actions/actionTypes';
import Immutable from 'seamless-immutable';
import { languages } from '../components/utils/Constants'

const initialState = Immutable({
    language: languages[localStorage.getItem("languageOption")]?.language || languages['ENGLISH']?.language 
});

export default function invoiceReducer(state = initialState, action) {
    switch (action.type) {
        case LANGUAGE_ACTION_TYPES.SELECTED_LANGUAGE:
            return Immutable(state).merge({
                language: action.language
            });
        default: return state;
    }
}
