import React from 'react';
import {Field,reduxForm} from 'redux-form';
import PropTypes from 'prop-types';

class VariableBillRefundForm extends React.Component {

  render() {
    const {handleSubmit, pristine, submitting, banks} = this.props;

    return (
      <form onSubmit={handleSubmit} className="dashboard-form">
        <div className="dashboard-input-wrap">
          <span className="profile-item-wrapper">
            <Field name="bank_id" className="dashboard-form__input dashboard-form__select" component="select" required>
              <option>Please select a Bank</option>
              {banks.map((bank) => (
                <option key={bank.id} value={bank.id}>{bank.name}</option>
              ))}
            </Field>
          </span>

          <span className="profile-item-wrapper">
            <Field component="input" className="dashboard-form__input" type="text" name="account_name" placeholder="Account Name"/>
          </span>

          <span className="profile-item-wrapper">
            <Field component="input" className="dashboard-form__input" type="text" name="account_number" placeholder="Account Number"/>
          </span>
        </div>

        <button className="dashboard__primary-btn dashboard-form__btn" disabled={pristine || submitting}>Submit</button>
      </form>
    )
  }
}

VariableBillRefundForm.propTypes = {
  banks: PropTypes.array,
  onSubmit: PropTypes.func,
};


VariableBillRefundForm = reduxForm({
  form: "variableBillRefundForm"
})(VariableBillRefundForm);

export default VariableBillRefundForm;