import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import LOGO from '../img/rh-new-logo.svg';

class PageNotFound extends React.Component{


    render(){

        return (
            <div className="container" style={{paddingBottom:"50px"}}>
                <div style={{paddingTop:"150px", textAlign:"center"}}>
                    <Link to={'/'}><img src={LOGO} alt="Reliance HMO's Logo" style={{maxWidth: '20rem'}}/></Link>
                </div>
                <div style={{textAlign:"center", fontSize:"200px", marginBottom:"10px", color:"#c5c4c4"}}>
                    404
                </div>
                <div style={{textAlign:"center", fontSize:"16px"}}>
                    Oops!...Nothing to see here
                    <br/>
                    <br/>
                    <Link to={'#'} >Go Home</Link>
                </div>
            </div>
        )
    }
}


export default withRouter(PageNotFound);