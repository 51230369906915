import React from 'react';
import PropTypes from 'prop-types';
import numberFormatter from 'number-formatter';
import moment from 'moment';
import { decodePaymentFrequency, decodeResourceStatus } from '../../services/utilityService';

const InvoiceListComponent = ({invoices}) => (
    invoices.length > 0 ? (
        <div className="dashboard-card dashboard-table-wrap">
          <div className="dashboard-table-overflow">
            <table className="dashboard-table">
                <thead>
                  <tr>
                      <th className="dashboard-table-item dashboard-table-item__header">Status</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Invoice Code</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Description</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Amount</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Payment Frequency</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Date Issued</th>
                  </tr>
                </thead>
                <tbody>
                {invoices.map((invoice)=>(
                    <tr key={invoice.reference_code}>
                      <td className="dashboard-table-item dashboard-table-item__body">{ decodeResourceStatus(invoice.active_status_id)?.toUpperCase()}</td>
                      <td className="dashboard-table-item dashboard-table-item__body"><a className="dashboard-table-item__link-item--underlined" href={`/dashboard/invoices/${invoice.reference_code}`}>{invoice.reference_code}</a></td>
                      <td className="dashboard-table-item dashboard-table-item__body">{`Payment for ${invoice.plan_count ? invoice.plan_count + " Plan(s)" : ""}`}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">&#8358;{`${numberFormatter("#,###.00",invoice.total_price)}`}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{decodePaymentFrequency(invoice.payment_frequency_id)}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{moment(invoice.created_at).format("dddd, MMMM Do YYYY")}</td>
                    </tr>
                ))}
                </tbody>
            </table>
          </div>
        </div>
    ) : (
        <div style={{textAlign:"center"}}>
            <p style={{fontSize:"3rem",color:"#ccc",margin:"10rem 5rem"}}>You have no invoices.</p>
        </div>
    )
);



InvoiceListComponent.propTypes = {
    invoices: PropTypes.array
};

export default InvoiceListComponent;