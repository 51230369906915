import React from 'react';

const MessageHolder = (props) => {
  return (
    <div style={{
      display: "grid", padding:"10px",
      margin:"0 auto", width: "90%",
      height: "50%", alignItems: "center",
      backgroundColor:"white",color:"#111"}}>
      <div>
        {props.children}
      </div>
    </div>
  )
};

export default MessageHolder;
