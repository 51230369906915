import React from 'react';
import queryString from 'querystring';
import PreAuthFormHolder from '../components/boxes/PreAuthFormHolder';
import MessageHolder from '../components/boxes/MessageHolder';
import ResetPasswordForm from '../components/forms/ResetPasswordForm';
import userService from '../services/userService';
import LoadingScreen from '../components/LoadingScreen';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      token: queryString.parse(props.location.search.substr(1)).token,
      isExpired: Date.now() > Date.parse(queryString.parse(props.location.search.substr(1)).expiry),
      error: null,
      loading: false,
      success: false,
      timer: null
    }
  }

  componentDidUpdate(_, prevState) {
    if(prevState.success !== this.state.success){
      if(this.state.success){
        this.setState({timer: setTimeout(()=>{this.props.history.push('/login')}, 2500)})
      }
    }

  }

  componentWillUnmount(){
    clearTimeout(this.state.timer)
  }

  checkPasswordMatch(values) {
    if (values.password === values.confirm_password) {
      return true;
    }
    return false;
  }

  resetPassword = (values) => {
    const match = this.checkPasswordMatch(values);

    if (match) {
      this.setState({loading: true})
      userService.resetPassword(values)
        .then(() => {
          this.setState({
            loading: false,
            success: true
          })
        })
        .catch(e => {
          this.setState({
            error: e.message,
            loading: false,
          })
        })
    } else {
      this.setState({error: this.props.language.reset_password.reset_password_page.notification.success.new_password.error.two})
    }
  }

  renderSuccessMessage() {
    return (
      <PreAuthFormHolder>
        <MessageHolder>
          <h3>{this.props.language.reset_password.reset_password_page.notification.success.new_password.notification.title}</h3>
          <p>
            {this.props.language.reset_password.reset_password_page.notification.success.new_password.notification.title}
          </p>
        </MessageHolder>
      </PreAuthFormHolder>
    )
  }

  renderExpiredMessage() {
    return (
      <PreAuthFormHolder>
        <MessageHolder>
          <h3>{this.props.language.reset_password.reset_password_page.notification.error.expired}</h3>
        </MessageHolder>
      </PreAuthFormHolder>
    )
  }

  render() {
    const {token, isExpired, error, loading, success} = this.state;

    if (loading) return <LoadingScreen />;

    if (success) return this.renderSuccessMessage();

    if (isExpired) return this.renderExpiredMessage();

    return (
      <PreAuthFormHolder message={error && error}>
        <ResetPasswordForm onSubmit={this.resetPassword} initialValues={{token}} language={this.props.language} />
      </PreAuthFormHolder>
    )
  }
}

function mapStateToProps(state) {
  const {language} = state.language
  return {language };
}

export default withRouter(connect(mapStateToProps)(ResetPassword));
