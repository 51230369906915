import React from 'react';
import PropTypes from 'prop-types';
import numberFormatter from 'number-formatter';

class CheckoutList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            useNewCard: true
        };
    }

    toggleUseNewCard = () => {
        this.setState({
            useNewCard: !this.state.useNewCard
        });
    };

    onPayNow = () => {
        const { plans, onCheckout } = this.props;
        onCheckout({
            cart: [
                ...plans.map((plan) => ({
                    product_id: plan.id,
                    no_of_enrollees: plan.quantity,
                    extensions: []
                }))
            ]
        });
    };

    calculateTotalPrice = () => {
        const { plans } = this.props;

        let totalPrice = plans.reduce((sum, plan) => sum + plan.price * plan.quantity, 0);

        return {
            totalPrice
        };
    };

    render() {
        const { plans, paymentFreq } = this.props;
        const price = this.calculateTotalPrice();

        return (
            <div>
                <h2 style={{ margin: '1rem 2rem' }}>
                    Here's a summary of your order. Please confirm and make changes where necessary.
                    Click "PAY NOW" when done to pay.
                </h2>
                <br />
                <br />
                <table className="dashboard-table">
                    <thead>
                        <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">
                                Plans
                            </th>
                            <th
                                className="dashboard-table-item dashboard-table-item__header"
                                style={{ textAlign: 'right' }}
                            >
                                Unit Price (NGN)
                            </th>
                            <th
                                className="dashboard-table-item dashboard-table-item__header"
                                style={{ textAlign: 'right' }}
                            >
                                Sub-Total Price(NGN)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {plans.map((plan) => (
                            <tr key={plan.id}>
                                <td className="dashboard-table-item dashboard-table-item__body">{`${plan.name} x ${plan.quantity}`}</td>
                                <td
                                    className="dashboard-table-item dashboard-table-item__body"
                                    style={{ textAlign: 'right' }}
                                >
                                    &#8358; {numberFormatter('#,###.00', plan.price)}
                                </td>
                                <td
                                    className="dashboard-table-item dashboard-table-item__body"
                                    style={{ textAlign: 'right' }}
                                >
                                    &#8358;{' '}
                                    {numberFormatter('#,###.00', plan.price * plan.quantity)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '1.5rem 2rem',
                        alignItems: 'center'
                    }}
                >
                    <h2 style={{ fontSize: '1.5rem' }}>TOTAL</h2>
                    <div>
                        <h3 style={{ fontSize: '2rem' }}>
                            &#8358; {numberFormatter('#,###.00', price.totalPrice)}
                        </h3>
                    </div>
                </div>

                <hr />

                <h3 style={{ margin: '1.5rem 2rem', fontSize: '1.3rem' }}>
                    Selected Payment Frequency:{' '}
                    <strong style={{ color: 'blue' }}>{paymentFreq.toUpperCase()}</strong>
                </h3>

                {/* <span className="dashboard-form-item" style={{paddingLeft: "2rem"}}>
          <label htmlFor="" style={{fontSize: "1.3rem", fontWeight: "bold"}}>Do you want to use a new card to pay? </label>
          <span className="dashboard-form__checklist-wrap">
            <span className="dashboard-form-item__radio-check-btn">
              <input type="radio" name="use_new_card" value="Yes" defaultChecked={this.state.useNewCard} id="new_card"/>
              <label htmlFor="new_card">Yes</label>
            </span>
            <span className="dashboard-form-item__radio-check-btn">
              <input type="radio" name="use_new_card" value="No" defaultChecked={!this.state.useNewCard} id="prev_card"/>
              <label htmlFor="prev_card">No, Use my previous card.</label>
            </span>
          </span>
        </span> */}

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '2rem'
                    }}
                >
                    <button
                        className="dashboard__primary-btn dashboard-form__btn"
                        onClick={this.onPayNow}
                        style={{ width: '100%', fontSize: '1.8rem', marginTop: 0 }}
                    >
                        PAY NOW
                    </button>
                </div>
            </div>
        );
    }
}

CheckoutList.propTypes = {
    plans: PropTypes.array,
    paymentFreq: PropTypes.string,
    onCheckout: PropTypes.func
};

export default CheckoutList;
