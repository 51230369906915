import React from 'react';
import numberFormatter from 'number-formatter';

export default function PlanSelectionInfo({ enrolleeList, total, onContinue, onBack }) {
  return (
    <>
      <div className="dashboard-card plan-expiry-notice">
        <h1 className="plan-expiry-notice__header-text">
          Plan selection
        </h1>
        <p className="plan-expiry-notice__body-text">
          You can choose to continue with your previous selected plan or change your plan
        </p>
        <p className="plan-expiry-notice__body-text">
          Please select all plans you would like to keep. You must select at least one plan to proceed. 
        </p>
        <p className="plan-expiry-notice__body-text">
          NB: You can only drop or modify plans here. You can purchase new plans from the <b>Enrollees</b> menu.
        </p>
        {
          enrolleeList()
        }
        <div className="dashboard-card__title-text cart__total__line-item--padded">
          <h3>Total</h3>
          <h3>₦{numberFormatter("#,###.00", total)}</h3>
        </div>
        <button
          className="dashboard__primary-btn--full-width"
          onClick={onContinue}>
          Continue
        </button>
        <button
          className="dashboard__secondary-btn--full-width"
          onClick={onBack}>
          Back
        </button>
      </div>
    </>
  );
}