import React from 'react';
import PropTypes from 'prop-types';
import utilityService from '../../services/utilityService';
import Modal from '../boxes/Modal';
import LoadingScreen from '../LoadingScreen';
import VariableBillRefundForm from "../forms/VariableBillRefundForm";

class VariableBillRefundModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      banks:null
    }
  }

  componentDidMount(){
    this.setState({
      isLoading:true
    });

    utilityService.getBanks()
      .then(banks=>{
        this.setState({
          isLoading: false,
          banks
        })
      })

  }

  render(){
    const {isLoading, banks} = this.state;
    const {onClose, onSubmit, open} = this.props;
    return(
      <Modal onClose={onClose} title={isLoading ? "Loading..." : "Please enter your bank details"} open={open}>
        {(isLoading || !banks) ? (
          <div style={{ position:"relative",width:"100%",height:"100px"}}>
            <LoadingScreen/>
          </div>
        ) : (
          <VariableBillRefundForm onSubmit={onSubmit} banks={banks}/>
        )}
      </Modal>
    )
  }
}

VariableBillRefundModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool
};

export default VariableBillRefundModal;