import React from 'react';
import {reduxForm, Field} from 'redux-form';
import { useTranslation } from 'react-i18next';

let ForgotPasswordForm = (props) => {
  const lang = localStorage.getItem('languageOption');
  const { t } = useTranslation('login');

    const {handleSubmit,pristine,submitting,language} = props;
    return (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <Field component="input" type="text" name="username" placeholder={t('resetpwd_input')} required/>
                <span className="dashboard-form-item">
                  <label id="reset_method_label" htmlFor="" className="profile-item__label" aria-label="Password reset method"/>
                  <span className="dashboard-form__checklist-wrap" aria-labelledby="reset_method_label">
                    <span className="dashboard-form-item__radio-check-btn">
                      <Field name="type" component="input" type="radio" value="1" id="email"/>
                      <label className={lang === 'ARABIC' ? 'label' : ''} style={{ textAlign: lang === 'ARABIC' ? 'right' : 'left' }} htmlFor="email">{t('fgtpwd_one')}</label>
                    </span>
                    <span className="dashboard-form-item__radio-check-btn">
                      <Field name="type" component="input" type="radio" value="2" id="phone" disabled/>
                      <label className={lang === 'ARABIC' ? 'label' : ''} style={{ textAlign: lang === 'ARABIC' ? 'right' : 'left' }} htmlFor="phone">{t('fgtpwd_two')}</label>
                    </span>
                  </span>
                </span>
                <input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value={t('fgtpwd_button')} />
            </form>
        </div>
    )
};


ForgotPasswordForm = reduxForm({
    form:'forgotPasswordForm'
})(ForgotPasswordForm);

export default ForgotPasswordForm