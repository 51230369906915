import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../boxes/Modal";

const AutoBillingModal = ({title, isVisible, onYes, content, onClose}) => {
  return (
    <Modal title={title} onClose={onClose} open={isVisible}>
      <div style={{maxWidth:"60rem"}}>
        <p style={{fontSize: "1.7rem", paddingBottom: "10px"}}>{content}</p>
        <p style={{fontSize: "1.7rem", paddingBottom: "10px"}}>Are you sure you want to {title[0].toLowerCase()}{title.slice(1)}?</p>
        <div className="button-header">
          <button
            className="dashboard__primary-btn"
            style={{background: "none", color:"#ab0404", marginTop: "2rem", minWidth:"0"}}
            onClick={onYes}>
            {'  '}
            YES
          </button>
          <button
            className="dashboard__primary-btn"
            style={{marginTop: "2rem", minWidth:"0", marginLeft: "2rem"}}
            onClick={onClose}>
            {'  '}
            NO
          </button>
        </div>
      </div>
    </Modal>
  )
};


AutoBillingModal.propTypes = {
  onYes: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string
};

export default AutoBillingModal;
