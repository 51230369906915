
const defaultStore = {
    user: {
        isFetching:true,
        isLoggedIn: false,
        invalidated:false,
        alert:"",
        error:null
    },
    invoice:{
        isFetching:true,
        invoices:[],
        selectedInvoice:{},
        error:{},
        alert:""
    },
    enrollees:{
        isFetching:true,
        allEnrollees:[],
        selectedEnrollee:{},
        error:{},
        alert:""
    },
    display:{
        showSideBar:false
    }
};

export default defaultStore;