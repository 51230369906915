import React from 'react';
import {Field, change, reduxForm} from 'redux-form';

class EditEnrolleeForm extends React.Component{

    componentDidMount(){
        const {dispatch, accessToken, user} = this.props;
        dispatch(change("editEnrolleeForm", "access_token",accessToken));
        dispatch(change("editEnrolleeForm", "user_id",user.id));
        dispatch(change("editEnrolleeForm", "first_name",user.first_name));
        dispatch(change("editEnrolleeForm", "last_name",user.last_name));
        dispatch(change("editEnrolleeForm", "email_address",user.email_address));
        dispatch(change("editEnrolleeForm", "phone_number",user.phone_number));
    }

    render(){
        const {handleSubmit, pristine, submitting} = this.props;

        return (
            <form onSubmit={handleSubmit} style={{display:"flex",flexDirection:"column"}}>
                <span className="profile-item-wrapper">
                    <label htmlFor="" className="profile-item__label">First Name</label>
                    <Field component="input" className="dashboard-form__input" type="text" name="first_name" placeholder=""/>
                </span>
                <span className="profile-item-wrapper">
                    <label htmlFor="" className="profile-item__label">Last Name</label>
                    <Field component="input" className="dashboard-form__input" type="text" name="last_name" placeholder=""/>
                </span>
                <span className="profile-item-wrapper">
                    <label htmlFor="" className="profile-item__label">Email Address</label>
                    <Field component="input" className="dashboard-form__input" type="text" name="email_address" placeholder=""/>
                </span>
                <span className="profile-item-wrapper">
                    <label htmlFor="" className="profile-item__label">Phone Number</label>
                    <Field component="input" className="dashboard-form__input" type="number" name="phone_number" placeholder=""/>
                </span>
                <Field component="input" type="hidden" name="user_id" style={{display:"none"}}/>
                <Field component="input" type="hidden" name="access_token" style={{display:"none"}}/>
                <button className="dashboard__primary-btn dashboard-form__btn" disabled={pristine || submitting}>Save Changes</button>
            </form>
        )
    }
}



EditEnrolleeForm = reduxForm({
    form:"editEnrolleeForm"
})(EditEnrolleeForm);

export default EditEnrolleeForm;