import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import PropsRoute from './utils/PropsRoute';
import EnrolleeDetailsContainer from '../containers/EnrolleeDetailContainer';
import EnrolleeListContainer from '../containers/EnrolleeListContainer';
import SignUpContainer from '../containers/SignUpContainer';
import withFeatureFlags from './utils/FeatureFlagWrapper';

const EnrolleeComponent = ({ match, user, flags: { manageEnrollees } }) => {
    if (!manageEnrollees) {
        return <Redirect to="/dashboard/home" />;
    }

    return (
        <Switch>
            <PropsRoute
                exact
                path={`${match.url}/:enrolleeId/complete-profile`}
                component={SignUpContainer}
                user={user}
            />

            <PropsRoute
                exact
                path={`${match.url}/:enrolleeId`}
                component={EnrolleeDetailsContainer}
                user={user}
            />
            <PropsRoute path={match.url} component={EnrolleeListContainer} user={user} />
        </Switch>
    );
};

export default withRouter(withFeatureFlags(EnrolleeComponent));
