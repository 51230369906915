import React from 'react';
import PropTypes from 'prop-types';
import EditEnrolleeForm from "../forms/EditEnrolleeForm";
import Modal from "../boxes/Modal";

const EditEnrolleeModal = (props) => {
    return (
        <Modal title="Edit Enrollee Details" onClose={props.onClose} open={props.open}>
            <EditEnrolleeForm
                onSubmit={props.onFormSubmit}
                accessToken={props.accessToken}
                user={props.user}/>
        </Modal>
    )
};

EditEnrolleeModal.propTypes = {
    onClose: PropTypes.func,
    onFormSubmit: PropTypes.func,
    accessToken: PropTypes.string,
    user: PropTypes.object
};

export default EditEnrolleeModal;