import React from 'react';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';

const InvoiceDetailsComponent = ({invoice, onToggleSubscription, autobillingSubscription, isSummary}) => {

  const handleClick = () => {
    invoice.autobill ? onToggleSubscription() : autobillingSubscription()  
  }
  return (
  <div className="dashboard-table-overflow">
    <table className="dashboard-table">
      <tbody>
        <tr>
          <th className="dashboard-table-item dashboard-table-item__header">Date Issued</th>
          <td className="dashboard-table-item dashboard-table-item__body">{moment(invoice.created_at).format("DD/MM/YYYY")}</td>
        </tr>

        <tr>
          <th className="dashboard-table-item dashboard-table-item__header">Cover Start Date</th>
          <td className="dashboard-table-item dashboard-table-item__body">{moment(invoice.start_date).format("DD/MM/YYYY")}</td>
        </tr>

        <tr>
          <th className="dashboard-table-item dashboard-table-item__header">Cover End Date</th>
          <td className="dashboard-table-item dashboard-table-item__body">{moment(invoice.end_date).format("DD/MM/YYYY")}</td>
        </tr>

        {!isSummary &&
        <tr>
          <th className="dashboard-table-item dashboard-table-item__header">Payment Status</th>
          <td className="dashboard-table-item dashboard-table-item__body">{getInvoiceStatusIndicator(invoice.payment_status_id)}</td>
        </tr>
        }

        <tr>
          <th className="dashboard-table-item dashboard-table-item__header">Amount</th>
          <td className="dashboard-table-item dashboard-table-item__body">&#8358;{numberFormatter("#,###.00",invoice.total_price)}</td>
        </tr>
      </tbody>
    </table>

      <div style={{marginTop: "4rem"}}>
        {onToggleSubscription && <>
          {invoice.autobill === 1 ? (
            <p className="dashboard-card__body-text">Please note this invoice is scheduled to be renewed automatically upon expiry. To stop automatic billing on this invoice, click the button below.</p>
          ):(
            <p className="dashboard-card__body-text">Please note this invoice is NOT scheduled to be renewed automatically upon expiry. To enable automatic billing on this invoice, click the button below.</p>
          )}
          <button className={`dashboard__secondary-btn__${invoice.autobill === 1 ? 'danger' : 'green'}`} value={invoice.autobill ? 'Cancel Automatic Billing' : 'Enable Automatic Billing'} onClick={handleClick}>
            {invoice.autobill ? 'Cancel Automatic Billing' : 'Enable Automatic Billing'}
          </button>
        </>}
      </div>
  </div>
  )
};

const getInvoiceStatusIndicator = (status) => {
    if(status === 1){
        return 'No Payment';
    }else if(status === 2){
        return 'Partial Payment';
    }else{
        return 'Complete Payment';
    }
};

InvoiceDetailsComponent.propTypes = {
   invoice: PropTypes.object
};


export default InvoiceDetailsComponent;
