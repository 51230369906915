import React from 'react';
import * as _ from 'lodash';
import queryString from 'querystring';
import {connect} from 'react-redux';
import {sessionService} from 'redux-react-session';
import {DOMAIN_COOKIE_NAME, getCookie} from "../../services/utilityService";
import LoadingScreen from '../LoadingScreen';

export function withUser(WrappedComponent){
  const mapStateToProps = ({session}) => ({
    sessionUser:session.user
  });
  return connect(mapStateToProps)(class extends React.Component {
      constructor(props){
        super(props);
        this.state = {
          isAuth:false,
          user: null,
          error:null,
          referrer: queryString.parse(props.location.search.substr(1)).r || (window.location.href.indexOf("localhost") > -1) ? "" : window.location.href
        }
      }

      componentDidMount(){

        //check cookie
        //if cookie exists,
        //    check session user
        //        if session user exists
        //             check if cookie and session user accesstoken are the same
        //                  if tokens are the same
        //                      return child component with user prop attached
        //                  if token are not the same
        //                      re-auth with cookie token, and refresh app
        //       if session user doesnt exist
        //            auth with cookie token and create session user
        //if cookie doesnt exists
        //    logout (clear session user) and redirect to accounts login.
        let cookie = getCookie(DOMAIN_COOKIE_NAME);
        if(cookie){
          sessionService.loadUser()
            .then(user=>{
              if(user.credentials.accessToken === cookie){
                //set authenticated as true
                this.setUser(user);
              }else{
                this.logout();
              }
            })
            .catch(e=>{
              console.log(e);
              this.logout();
            })
        }else{
          console.log("no cookie");
          this.logout();
        }
      }

      setUser = (user) => {
        this.setState({
          user,
          isAuth:true
        })
      };

      logout = () => {
        this.props.history.push(`/logout?r=${encodeURI(this.state.referrer)}`);
      };

      componentDidUpdate(){
        if(!_.isEmpty(this.props.sessionUser) && this.props.sessionUser !== this.state.user){
          this.setUser(this.props.sessionUser);
        }
      }

      render(){
        return this.state.isAuth ? <WrappedComponent user={this.state.user} {...this.props}/> : <LoadingScreen/>
      }
    })
}

