import React from 'react';
import PropTypes from 'prop-types';
import InvoiceModalListComponent from "../InvoiceModalListComponent";
import Modal from "../boxes/Modal";

const InvoiceListModal = ({onClose, invoices, open}) => {
    return(
        <Modal title="Please select an Invoice" onClose={onClose} open={open}>
            <InvoiceModalListComponent invoices={invoices}/>
        </Modal>
    )
};

InvoiceListModal.propTypes = {
    onClose: PropTypes.func,
    invoices: PropTypes.array
};

export default InvoiceListModal;