import React from 'react';
import numberFormatter from 'number-formatter';

export default function VariableBillingSelectPayment({variableBill, schedule, onSelect, total, onPayInvoice, onBack}) {
  return (
    <>
      <div className="dashboard-card plan-expiry-notice text_center">
        <h1 className="plan-expiry-notice__header-text">
          Pay Variable Bill
          </h1>
        <p className="plan-expiry-notice__body-text">
          Your total variable bill is ₦{
            numberFormatter(
              "#,###.00",
              variableBill
            )
          }
        </p>
        <p className="plan-expiry-notice__body-text">
          Paying this bill allows you keep your graduated benefits while your plan was expired.
        </p>
        <p className="plan-expiry-notice__body-text">
          Please select how you would like to pay
        </p>
        <form className="form">
          <span style={{width: "100%"}} className="dashboard-form__checklist-wrap">
            <span className="dashboard-form-item__radio-check-btn">
              <input
                type="radio"
                id="payment_plan_once"
                name="payment_plan"
                value="once"
                checked={schedule === "once"}
                onChange={onSelect}
                required
              />
              <label htmlFor="payment_plan_once">At Once</label>
            </span>
            <span className="dashboard-form-item__radio-check-btn">
              <input
                type="radio"
                id="payment_plan_three_months"
                name="payment_plan"
                value="monthly"
                checked={schedule === "monthly"}
                onChange={onSelect}
                required
              />
              <label htmlFor="payment_plan_three_months">Over Three Months</label>
            </span>
          </span>
          <button onClick={onPayInvoice} className="dashboard__primary-btn--full-width">
            Pay ₦{numberFormatter("#,###.00", total)}
          </button>
        </form>
        <button
          className="dashboard__secondary-btn--full-width"
          onClick={onBack}
        >
          Back
        </button>
      </div>
    </>
  );
}