import React from 'react';

export default function VariableBillingNotice({ onReactivate, onRestart }) {
  return (
    <>
      <div className="dashboard-card plan-expiry-notice text_center">
        <h1 className="plan-expiry-notice__header-text">
          Important!
        </h1>
        <h1 className="plan-expiry-notice__header-text">
          <span role="img" aria-label="Notice">👇</span>
        </h1>
        <p className="plan-expiry-notice__body-text">
          While you were away, we held your graduated benefits for you.
          To maintain your benefits and get instance access to care, please
          pay the variable billing fee for the time you were away.
        </p>
        <p className="plan-expiry-notice__body-text">
          Don't want to pay the fee? Restart your plan and graduated
          benefits from month zero. You will need to wait 7 days to
          access care after payment.
        </p>
        <p className="plan-expiry-notice__body-text">
          To ensure you maintain your graduated benefits and get instant
          access to care in the future, ensure you never cancel your plan.
        </p>
        <button
          className="dashboard__primary-btn article__btn"
          onClick={onReactivate}
        >
          Reactivate Plan
        </button>
        &nbsp;
        &nbsp;
        <button
          className="dashboard__secondary-btn article__btn"
          onClick={onRestart}
        >
          Restart Plan
        </button>
      </div>
    </>

  );
}