// featureFlags.js
export const isFeatureEnabled = (feature) => {
    const featureEnvVar = `REACT_APP_FEATURE_${feature.toUpperCase()}`;
    return process.env[featureEnvVar] === 'true';
};

export const getEnvValue = (key) => {
    const envVar = `REACT_APP_${key.toUpperCase()}`;
    return process.env[envVar] || '';
};
