import {ENROLLEE_ACTION_TYPES} from './actionTypes';
import userService from '../services/userService';

//////////////////// ACTIONS  //////////////////////

const getEnrollees = () => {
    return {
        type: ENROLLEE_ACTION_TYPES.GET_ENROLLEES
    }
};

const getEnrolleesSuccess = (enrollees) => {
    return {
        type: ENROLLEE_ACTION_TYPES.GET_ENROLLEES_SUCCESS,
        data: enrollees
    }
};

const getEnrolleesFailed = (error) => {
    return {
        type: ENROLLEE_ACTION_TYPES.GET_ENROLLEES_FAIL,
        data: error
    }
};

const getEnrolleeById = () => {
    return {
        type: ENROLLEE_ACTION_TYPES.GET_ENROLLEE_BY_ID
    }
};

const getEnrolleeByIdSuccess = (enrollee) => {
    return {
        type: ENROLLEE_ACTION_TYPES.GET_ENROLLEE_BY_ID_SUCCESS,
        data: enrollee
    }
};

const getEnrolleeByIdFailed = (error) => {
    return {
        type: ENROLLEE_ACTION_TYPES.GET_ENROLLEE_BY_ID_FAIL,
        data: error
    }
};

const editEnrollee = () => {
    return {
        type: ENROLLEE_ACTION_TYPES.EDIT_ENROLLEE
    }
};

const editEnrolleeSuccess = (successMessage) => {
    return {
        type: ENROLLEE_ACTION_TYPES.EDIT_ENROLLEE_SUCCESS,
        data: successMessage
    }
};

const editEnrolleeFailed = (error) => {
    return {
        type: ENROLLEE_ACTION_TYPES.EDIT_ENROLLEE_FAIL,
        data: error
    }
};

const transferEnrollee = () => {
    return {
        type: ENROLLEE_ACTION_TYPES.TRANSFER_ENROLLEE
    }
};

const transferEnrolleeSuccess = (successMessage) => {
    return {
        type: ENROLLEE_ACTION_TYPES.TRANSFER_ENROLLEE_SUCCESS,
        data: successMessage
    }
};

const transferEnrolleeFailed = (error) => {
    return {
        type: ENROLLEE_ACTION_TYPES.TRANSFER_ENROLLEE_FAIL,
        data: error
    }
};

const removeEnrollee = () => {
    return {
        type: ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE
    }
};

const removeEnrolleeSuccess = (successMessage) => {
    return {
        type: ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_SUCCESS,
        data: successMessage
    }
};

const removeEnrolleeFailed = (error) => {
    return {
        type: ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_FAIL,
        data: error
    }
};

const removeEnrolleeExtension = () => {
  return {
    type: ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_EXTENSION
  }
};

const removeEnrolleeExtensionSuccess = (successMessage) => {
  return {
    type: ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_EXTENSION_SUCCESS,
    data: successMessage
  }
};

const removeEnrolleeExtensionFailed = (error) => {
  return {
    type: ENROLLEE_ACTION_TYPES.REMOVE_ENROLLEE_EXTENSION_FAIL,
    data: error
  }
};

const setLoading = () => {
    return {
        type: ENROLLEE_ACTION_TYPES.START_LOADING
    }
};

const stopLoading = () => {
    return {
        type: ENROLLEE_ACTION_TYPES.STOP_LOADING
    }
};


////////////////////// THUNKS  ////////////////////////////

export const setEnrolleeLoading = (isLoading) => {
    return ((dispatch)=>{
        if(isLoading){
            dispatch(setLoading());
        }else{
            dispatch(stopLoading());
        }
    })
};


export const getUserEnrollees = () => {
    return ((dispatch)=>{
        dispatch(getEnrollees());
        userService.getEnrollees()
            .then((enrollees)=>{
                dispatch(getEnrolleesSuccess(enrollees));
            }, (err)=>{
                dispatch(getEnrolleesFailed({message:err.message, code:err.code}))
            })
    });
};

export const getUserEnrolleeById = (id) => {
    return ((dispatch)=>{
        dispatch(getEnrolleeById());
        userService.getEnrolleeById(id)
            .then((enrollee)=>{
                dispatch(getEnrolleeByIdSuccess(enrollee));
            }, (err) => {
                dispatch(getEnrolleeByIdFailed({message:err.message, code:err.code}));
            })
    })
};

export const editUserEnrollee = (userId, json) => {
  return ((dispatch)=>{
      userService.editEnrolleeDetails(userId, json)
          .then((message)=>{
            dispatch(editEnrolleeSuccess(message));
          }, (err)=>{
            dispatch(editEnrolleeFailed(
                {
                    message:err.message || "Oops!...Something seems to have gone wrong. Please contact hellonigeria@getreliancehealth.com for support.",
                    code:err.code
                }));
          })
  })
};

export const editUserSubscription = (userId, json) => {
    return ((dispatch)=>{
        dispatch(editEnrollee());
        userService.editEnrolleeSubscription(userId, json)
            .then((message)=>{
              dispatch(editEnrolleeSuccess(message));
            }, (err)=>{
              dispatch(editEnrolleeFailed(
                  {
                      message:err.message || "Oops!...Something seems to have gone wrong. Please contact hellonigeria@getreliancehealth.com for support.",
                      code:err.code
                  }));
            })
    })
  };

export const transferUserEnrollee = (eId, history) => {
    return ((dispatch)=>{
        dispatch(transferEnrollee());
        userService.transferEnrollee(eId)
            .then(message=>{
                dispatch(transferEnrolleeSuccess(message));
                history.push("/dashboard/enrollees")
            },err=>{
                dispatch(transferEnrolleeFailed({message:err.message, code:err.code}));
            })
    })
};

export const removeUserEnrollee = (eId, history) => {
    return ((dispatch)=>{
        dispatch(removeEnrollee());
        userService.removeEnrollee(eId)
            .then(()=>{
                dispatch(removeEnrolleeSuccess("Successful! The Enrollee removal process has started and will be completed by the end of your billing cycle. For help/enquires please contact hellonigeria@getreliancehealth.com"));
                history.push("/dashboard/enrollees")
            }, err=>{
                dispatch(removeEnrolleeFailed(err));
            })
    })
};

export const removeUserExtension = (userId, extId) => {
    return (dispatch=>{
        dispatch(removeEnrolleeExtension());
        userService.removeEnrolleeExtension(userId, extId)
          .then(()=>{
            dispatch(removeEnrolleeExtensionSuccess({message:"Successful! The Extension will be completely removed by the end of your billing cycle. For help/enquires please contact hellonigeria@getreliancehealth.com",id:extId}))
          },err=>{
            dispatch(removeEnrolleeExtensionFailed(err));
          })

    })
};