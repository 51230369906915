import React, { Component } from 'react'
import MainContent from '../boxes/MainContent'
import PageHeader from "../boxes/PageHeader";
import PageBody from "../boxes/PageBody";

export default class HelpComponent extends Component {

  render() {

    return (
      <MainContent>
        <PageHeader title="Help" />
        <PageBody>
          <div className="help-section">
            <div className="dashboard-card faq-content">
              <h1>Frequently Asked Questions</h1>
              <p className="question">Click on a question to see more details</p>
              <div className="accordion">
                <span className="target-fix" id="accordion"></span>

                <div>
                  <span className="target-fix" id="accordion1"></span>
                  <a className="for-bottom" href="#accordion1" id="open-accordion1" title="open">I mistakenly entered the wrong name/ phone number/ email. How do I correct it? </a>
                  <a href="#accordion" id="close-accordion1" title="close">I mistakenly entered the wrong name/ phone number/ email. How do I correct it? </a>
                  <div className="accordion-content">
                    <p>On the enrollee list page, click on the account with the wrong details. At the top right corner, 
                    there is a button to edit the profile. Click that button and enter the correct changes. Once you 
                    click on save changes, the account details will be updated. Please note that this is only available 
                    for incomplete profiles. Once a profile becomes active, you will need to contact customer support. </p>
                  </div>
                </div>
                <div>
                  <span className="target-fix" id="accordion2"></span>
                  <a className="for-bottom" href="#accordion2" id="open-accordion2" title="open">I mistakenly deactivated my plan, how can I correct this? </a>
                  <a href="#accordion" id="close-accordion2" title="close">I mistakenly deactivated my plan, how can I correct this? </a>
                  <div className="accordion-content">
                    <p>simple! Open the account that you just deactivated and click on "reactivate account" at the same place where you 
                    deactivated the plan initially. Your account will be reactivated and you can go continue with your plan like nothing
                     happened. Please note that when you reactivate your plan, you will be billed for it at your next payment date. .</p>
                  </div>
                </div>

                <div>
                  <span className="target-fix" id="accordion3"></span>
                  <a className="for-bottom" href="#accordion3" id="open-accordion3" title="open">How do I know if my account is active or not? </a>
                  <a href="#accordion" id="close-accordion3" title="close">How do I know if my account is active or not? </a>
                  <div className="accordion-content">
                    <p>This information is available under the status column on the enrollee list page. Active means that the plan has 
                    been assigned to the user and the user has inputted their full details along with their hospital choice, picture etc.
                     An incomplete profile means that the plan has been assigned, but the user hasn't completed the sign-up form sent to their email.
                      Suspended means that you've reached the end of your cover period, but we are giving you 7 days of grace to make payment and keep 
                      your benefits. If you don't pay within this grace period, your account status will change to expired. </p>
                  </div>
                </div>

                <div>
                  <span className="target-fix" id="accordion4"></span>
                  <a className="for-bottom" href="#accordion4" id="open-accordion4" title="open">I'm no longer interested in paying for health insurance, how can I cancel? </a>
                  <a href="#accordion" id="close-accordion4" title="close">I'm no longer interested in paying for health insurance, how can I cancel?  </a>
                  <div className="accordion-content">
                    <p>We are sad to let you go, but to stop making payment, you can deactivate your account. Click on the account name 
                    and click on "deactivate account" at the top right corner. You will be able to continue using your plan until it expires,
                     and you will not be billed for it. Alternatively, you can turn off autobilling. </p>
                  </div>
                </div>

                <div>
                  <span className="target-fix" id="accordion5"></span>
                  <a className="for-bottom" href="#accordion5" id="open-accordion5" title="open">What is autobilling?  </a>
                  <a href="#accordion" id="close-accordion5" title="close">What is autobilling?  </a>
                  <div className="accordion-content">
                    <p>Autobilling means that we will automatically debit your bank account with the agreed amount when
                     its time for your plan to be renewed (monthly, quarterly, or annually). Just like youre paying for Netflix 
                     or topping up your PiggyVest account. That way you dont need to remember to pay, we do that for you. If you
                      don't want this, you can turn it off from your invoice. You can always go back to turn it back on. </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-card dash-contact">
              <h1>Contact Us</h1>
              <p>If you have any issues our Reliance Help Centre agents are always happy to help via:</p>
              <div className="social-section">
                <p>Email: <span><a href="mailto:hellonigeria@getreliancehealth.com?subject=Enquiry">hellonigeria@getreliancehealth.com</a></span></p>
                <p>Telephone: <span><a href="tel:070073542623">0700-7354-2623</a></span></p>
                <p>Whatsapp: <span><a href="https://wa.me/23417001580">017001580</a></span></p>
                <p>Facebook: <span><a target="_blank" rel="noopener noreferrer" href="https://web.facebook.com/reliancehmo/?_rdc=1&_rdr">@reliancehmo</a></span></p>
              </div>
              <p>Thanks for choosing RelianceHMO.</p>
            </div>
          </div>
        </PageBody>
      </MainContent>
    )
  }
}
