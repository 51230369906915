import React from 'react';
import {reduxForm, Field} from 'redux-form';

let ResetPasswordForm = (props) => {
	const {handleSubmit,pristine,submitting,language} = props;
	return (
		<div className="form" style={{height: "50%"}}>
			<h3 style={{"margin": "10px 0"}}>
				{language.reset_password.reset_password_page.notification.success.new_password.title}
			</h3>
			<form onSubmit={handleSubmit}>
        		<label htmlFor="password" style={{display: "flex"}}>{language.reset_password.reset_password_page.notification.success.new_password.input1.label}</label>
				<Field component="input" id="password" type="password" minLength="6" name="password" placeholder="000000" style={{marginTop: "5px"}} required/>
        		<label htmlFor="confirm_password" style={{display: "flex"}}>{language.reset_password.reset_password_page.notification.success.new_password.input2.label}</label>
        		<Field component="input" id="confirm_password" type="password" minLength="6" name="confirm_password" placeholder="000000" style={{marginTop: "5px"}} required/>
				<input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value={language.reset_password.reset_password_page.notification.success.new_password.button} />
			</form>
		</div>
	)
};


ResetPasswordForm = reduxForm({
    form:'resetPasswordForm'
})(ResetPasswordForm);

export default ResetPasswordForm;
