import React from 'react';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import Modal from '../boxes/Modal';

class PlanUpgradeModal extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedPlan: null,
      benefitUrl: ""
    }
  }

  selectPlan = (plan) => {
    let benefitUrl = "https://reliancehmo.com/benefits";
    if(plan.extension) {
      if(plan.extension.code.toLowerCase() === "pec"){
        benefitUrl = "https://reliancehmo.com/pec-benefits"
      }
    }

    this.setState({
      selectedPlan: plan,
      benefitUrl
    })
  }

  render(){
    const {selectedPlan, benefitUrl} = this.state;
    const {plans, frequency="monthly", onClose, onUpgrade, enrollee, open} = this.props;

    return (
      <Modal open={open} title="Upgrade Plan?" onClose={onClose}>
        <>
          <p className="dashboard-card__body-text">
              Please select a plan to upgrade {`${enrollee.first_name} ${enrollee.last_name}`}'s to.
          </p>
          <div className="payment-freq-modal-wrap ">
            {plans.map((plan)=>{
              return (
                <div key={plan.id}>
                  <h3 className="dashboard-card__title-text">{plan.name}</h3>
                  <p className="plan-pricetag">&#8358;{numberFormatter("#,###.00",plan.price[frequency])}</p>
                  <p className="dashboard-card__body-text"></p>
                  <button 
                    onClick={()=>this.selectPlan(plan)} 
                    className={(selectedPlan && selectedPlan.id === plan.id) ? 'dashboard__primary-btn dashboard-control__button' : 'dashboard__secondary-btn'}>
                      {(selectedPlan && selectedPlan.id === plan.id) ? 'Selected' : 'Select'}
                  </button>
                </div>
              )
            })}
          </div>
          {selectedPlan &&
            <>
              <p className="dashboard-card__body-text">
                  This upgrade would cost you NGN{`${numberFormatter("#,###.00",selectedPlan.price[frequency])}`}, which will be automatically deducted from your
                  card by your next renewal.
              </p>
              <p className="dashboard-card__body-text">
                  For more information on what this plan offers, check the 
                  <a className="modal-link-item"
                    href={benefitUrl} target="_blank" rel="noopener noreferrer">
                      Benefit Page
                  </a>
              </p>
              <p className="dashboard-card__body-text">
                  Click on 'Continue' below, to proceed with the upgrade, or click 'Cancel' to go back.
              </p>
            </>
          }          
          <div className="dashboard-btn-section" style={{marginBottom: "0"}}>
              <button className="dashboard__primary-btn dashboard-control__button" onClick={onUpgrade} disabled={!selectedPlan}>Continue</button>
              <button className="dashboard__secondary-btn" style={{marginLeft: "2rem"}} onClick={onClose}>Cancel</button>
          </div>
        </>
      </Modal>
    )
  }
}


PlanUpgradeModal.propTypes = {
  plan: PropTypes.object,
  frequency: PropTypes.string,
  enrollee: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpgrade: PropTypes.func
};

export default PlanUpgradeModal;