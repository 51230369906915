import React from 'react';
import queryString from 'querystring';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import * as userActions from '../actions/user';
import LoadingScreen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';
import PageBody from '../components/boxes/PageBody';
import LOGO from '../img/rh-new-logo.svg';
import SimplePageHeader from "./boxes/SimplePageHeader";


class AuthComponent extends React.Component {

  constructor(props){
    super(props);
    let accessToken = queryString.parse(props.location.search.substr(1)).at;
    let to = queryString.parse(props.location.search.substr(1)).to;
    this.state = {
      accessToken,
      to: to || '/dashboard/enrollees'
    }
  }

  componentDidMount(){
    const {dispatch} = this.props;
    const {accessToken, to} = this.state;
    dispatch(userActions.loginWithAccessToken(accessToken,to));
  }

  goTo(url) {
    const {history} = this.props;
    history.push(url);
  }

  render(){
    const {checked, authenticated, error, user} = this.props;

    if(checked && authenticated && !_.isEmpty(user)) {        
      return <Redirect to={this.state.to} />;
    }
    if(error){
      return <Redirect to={"/login"} />;      
    }


    return (
      <MainContent>
        <SimplePageHeader title="" logo={LOGO}/>
        <PageBody>
          <LoadingScreen/>
        </PageBody>
      </MainContent>
    )
  }

}

function mapStateToProps(state){
  const { isFetching, error } = state.user || {};
  const { checked, authenticated, user } = state.session || {};
  return { isFetching, error, checked, authenticated, user };
}
export default withRouter(connect(mapStateToProps)(AuthComponent));