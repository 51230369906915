import React from 'react';
import { SyncLoader } from 'react-spinners';
import '../index.css';

const LoadingScreen = () => {
    return (
        <div style={{ margin: "0", position: "absolute", top: "50%",
         left: "50%", transform: "translate(-50%,-50%)", display: 'flex', zIndex: '999' }}>
            <div style={{ margin: "0 auto", width: "fit-content" }}>
                <SyncLoader color={'#107BC0'} />
            </div>
        </div>
    )
};

export default LoadingScreen