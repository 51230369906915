import {USER_ACTION_TYPES} from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  isFetching: false,
  isLoggedIn: false,
  invalidated: false,
  alert: "",
  error: null
});

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_ACTION_TYPES.LOGIN:
    case USER_ACTION_TYPES.UPDATE_PROFILE:
    case USER_ACTION_TYPES.START_LOADING:
    case USER_ACTION_TYPES.RESET_PASSWORD:
      return Immutable(state).merge({
        isFetching: true
      });
    case USER_ACTION_TYPES.LOGIN_FAIL:
      return Immutable(state).merge({
        isFetching: false,
        error: action.data
      });
    case USER_ACTION_TYPES.LOGIN_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        isLoggedIn: true
      }, {deep: true});
    case USER_ACTION_TYPES.UPDATE_PROFILE_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        invalidated: true
      });
    case USER_ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        error: null,
        alert: "Password Reset Successful!",
        recoveryMessage: action.data
      });
    case USER_ACTION_TYPES.RESET_PASSWORD_FAIL:
      return Immutable(state).merge({
        isFetching:false,
        error: action.data,
        recoveryMessage: null
      });
      case USER_ACTION_TYPES.STOP_LOADING:
      return Immutable(state).merge({
        isFetching: false
      });
    default:
      return state;
  }
}