import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getLanguageText } from '../../actions/language';
import { languages, getLanguage } from './Constants';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { findLocaleByValue, getLocaleConfig, getSupportedLocales } from '../../services/utilityService';


export default function LanguagueSelector(){
    const { language } = useSelector(state => state.language);
    const [selectedLanguage, setSelectedLanguage] = useState(language)
    const dispatch = useDispatch();
    const lang = localStorage.getItem('languageOption') || selectedLanguage;
    const { t } = useTranslation('common');
    const handleChangeSelector= (e) => {
        setSelectedLanguage(e.target.value)
        localStorage.setItem("languageOption", e.target.value)
        i18n.changeLanguage(e.target.value === 'ENGLISH' ? 'en-NG' : getSupportedLocales("primary"));
        dispatch(getLanguageText(getLanguage(e.target.value)))
    }

    useEffect(() => {
        document.getElementById('root').dir = i18n.dir();
        const stateLanguageName = localStorage.getItem('languageOption') || (getLocaleConfig()[getSupportedLocales("primary")]).toUpperCase();
        const [statelocaleCode, _] = findLocaleByValue(stateLanguageName);
        if (!getSupportedLocales().includes(statelocaleCode)) {
            handleChangeSelector({ target: { value: (getLocaleConfig()[getSupportedLocales("primary")]).toUpperCase() } });
        }
    }, [selectedLanguage]);

    return (
        <div className='language_selector'>
            <select name="language" id="language" value={lang} onChange={handleChangeSelector} style={{border:0, outline:'none'}}>
                {Object.values(languages).map((i, index) => {
                    // eslint-disable-next-line
                    const [localeCode, localeName] = findLocaleByValue(i.value);
                    return <option key={index} value={i.value} >&#127758;&nbsp; {t(localeName.toLowerCase(), { ns:'common', lng: localeCode })}&nbsp;&nbsp;</option>
                })}
            </select>
        </div>
    )
};