import React from 'react';

export default function VariableBillingReviewEnrollees({enrolleeList, onContinue, onBack}) {
    return (
      <>
        <div className="dashboard-card plan-expiry-notice">
          <h1 className="plan-expiry-notice__header-text">
            Review plan(s)
          </h1>
          <p className="plan-expiry-notice__body-text">
            Before you proceed to payment, please review your current plan(s). 
          </p>
          <p className="plan-expiry-notice__body-text">
            Please select all plans you would like to keep. You must select at least one plan to proceed. 
          </p>
          <p className="plan-expiry-notice__body-text">
            NB: You can only drop plans here. You can add or increase plans after paying this bill.
          </p>
          <p className="plan-expiry-notice__body-text">
            Any Enrollees dropped here will no longer be eligible for variable billing should you choose to renew in the future.
          </p>
          {enrolleeList()}
          <button
            className="dashboard__primary-btn--full-width"
            onClick={onContinue}>
            Continue
          </button>
          <button
            className="dashboard__secondary-btn--full-width"
            onClick={onBack}>
            Back
          </button>
        </div>
      </>
    );
}