import React from 'react';
import PropTypes from 'prop-types';
import PlanUpgradeModal from "./modals/PlanUpgradeModal";
import utilityService, { decodePaymentFrequency } from '../services/utilityService';

class PlanUpgradeComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      currentPlan:null,
      plans:[],
      canUpgrade: false,
      showUpgradeModal: false
    }
  }

  toggleUpgradeModal = ()=> {
    this.setState({
      showUpgradeModal:!this.state.showUpgradeModal
    })
  };

  componentDidMount(){    
    utilityService.getPlans("all")
      .then(plans=>{
        let currentPlan = plans.filter(plan=>plan.id === this.props.exclude)[0];
        let availablePlans = [];

        if(!currentPlan || plans.length === 0 ) 
          return;

        plans.forEach(plan=>{
          if(plan.id !== currentPlan.id && plan.price.monthly > currentPlan.price.monthly){
            availablePlans.push(plan);
          }
        });

        this.setState({
          currentPlan,
          plans: availablePlans,
          canUpgrade: availablePlans.length > 0
        })


      })
  }

  onUpgrade = (selectedPlan) => {
    this.props.callback(selectedPlan);
    this.toggleUpgradeModal();
  };

  render(){
    const {canUpgrade, plans, showUpgradeModal} = this.state;
    const {enrollee} = this.props;

    if(!canUpgrade) return null;

    return (
      <React.Fragment>
        <PlanUpgradeModal  //upgradeModal shows onClicking upgrade plan button
          plans={plans}
          open={showUpgradeModal}
          frequency={decodePaymentFrequency(enrollee.payment_frequency_id).toLowerCase()}
          onClose={this.toggleUpgradeModal}
          onUpgrade={this.onUpgrade}
          enrollee={enrollee}/>
        <button
          className="dashboard__secondary-btn"
          id="upgradePlan"
          onClick={this.toggleUpgradeModal}>
          Upgrade Plan
        </button>
      </React.Fragment>
    )
  }

}


PlanUpgradeComponent.propTypes = {
  plans: PropTypes.array,
  exclude: PropTypes.number,
  callback: PropTypes.func
};

export default PlanUpgradeComponent;