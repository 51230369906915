import React from 'react';
import * as _ from 'lodash';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import userService from '../services/userService';
import utilityService, { getAccountUrl, healthBenefitsUrl } from '../services/utilityService';
import LoadingScreen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import NotificationModal from './modals/NotificationModal';
import OrderSummaryModal from './modals/OrderSummaryModal';
import { ItemContextSwitcher } from './ItemContextSwitcher';
import withFeatureFlags from './utils/FeatureFlagWrapper';
import ChangePlanModal from './modals/ChangePlanModal';

// name should be lowercase because it is used in setPaymentFrequency
export const PaymentFrequency = Object.freeze({
    QUARTERLY: { id: 2, name: 'quarterly' },
    YEARLY: { id: 3, name: 'yearly' }
});

const PAYMENT_FREQUENCIES = Object.values(PaymentFrequency);

class NewInvoiceComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            providers: [],
            providersCountData: {},
            paymentFrequency: PAYMENT_FREQUENCIES[0],
            selectedPlans: [],
            showCheckoutModal: false,
            paymentSuccessfulModal: null,
            isLoading: false,
            isAllowed: true,
            error: null,
            showChangePlanModal: false,
            previousPaymentFrequency: null
        };
    }

    componentDidMount() {
        this.setLoading(true);

        utilityService.getPlans().then((response) => {
            let allowedPlanIds = this.props?.flags?.allowedPlanIds;

            const filteredPlans = response.filter((plan) => allowedPlanIds.includes(plan.id));

            this.setState({
                plans: filteredPlans
            });

            const productIds = filteredPlans.map((plan) => plan.id).join();

            utilityService.getProviders(productIds).then((response) => {
                this.setState({
                    providers: response
                });
                this.getProductCount();
                this.setLoading(false);
            });
        });
    }

    getProductCount = () => {
        const { providers, plans } = this.state;
        const allProviders = providers;
        const allPlans = plans;

        let providersCountData = {};

        allPlans.forEach((plan) => {
            let scopedProviders = allProviders.filter((provider) => {
                return provider.products.indexOf(plan.id.toString()) !== -1;
            });

            providersCountData[plan.id] = scopedProviders.length;
        });

        this.setState({
            providersCountData
        });
    };

    setLoading = (isLoading) => {
        this.setState({
            isLoading: isLoading
        });
    };

    setPaymentFrequency = (selectedFreq) => {
        // debugger;
        const oldSP = [...this.state.selectedPlans];
        const plans = [...this.state.plans];

        if (
            this.state.paymentFrequency !== selectedFreq &&
            this.state.selectedPlans.length &&
            !this.state.showChangePlanModal
        ) {
            this.setState({
                showChangePlanModal: true,
                previousPaymentFrequency: this.state.paymentFrequency,
                paymentFrequency: selectedFreq
            });
            return;
        }

        this.setState({
            paymentFrequency: selectedFreq,
            selectedPlans:
                oldSP.length > 0
                    ? oldSP.map((sp) => {
                          return {
                              id: sp.id,
                              name: sp.name,
                              price: (sp.price = plans.filter((p) => p.name === sp.name)[0].price[
                                  selectedFreq.name
                              ]),
                              quantity: sp.quantity
                          };
                      })
                    : []
        });
    };

    /**
     * Adds an item to the plan list.
     *
     * @param {number} planId - The ID of the plan.
     * @param {string} planName - The name of the plan.
     * @param {number} planPrice - The price of the plan.
     * @param {number} quantity - The quantity of the plan.
     */
    onAddToPlanList = (planId, planName, planPrice, quantity) => {
        // debugger;
        const plan = this.state.selectedPlans.find((plan) => plan.id === planId) || null;

        if (!plan) {
            this.setState({
                selectedPlans: [
                    ...this.state.selectedPlans,
                    { id: planId, name: planName, price: planPrice, quantity: quantity }
                ]
            });
        } else {
            const oldSP = this.state.selectedPlans;
            this.setState({
                selectedPlans: oldSP.map((p) =>
                    p.id === plan.id
                        ? {
                              id: planId,
                              name: planName,
                              price: planPrice,
                              quantity: quantity
                          }
                        : p
                )
            });
        }
    };

    onRemoveFromPlanList = (id) => {
        const oldSP = this.state.selectedPlans;
        _.remove(oldSP, (plan) => plan.id === id);
        this.setState({
            selectedPlans: oldSP
        });
    };

    onRemoveAllPlans = () => {
        this.setState({
            selectedPlans: []
        });
    };

    toggleCheckoutModal = () => {
        this.setState({
            showCheckoutModal: !this.state.showCheckoutModal
        });
    };

    goToAddEnrollee = (url) => {
        const { history } = this.props;
        setTimeout(() => {
            history.push(url);
        }, 1000);
    };

    onCheckout = (json) => {
        json.meta = {
            payment_frequency: this.state.paymentFrequency.name[0],
            for: 'others',
            callback_url: getAccountUrl() + '/purchase-complete'
        };

        this.toggleCheckoutModal();

        this.setLoading(true);
        userService
            .buyPlansAndExtensions(json)
            .then((response) => {
                if (!response.use_new_card) {
                    this.setState({
                        isLoading: false,
                        paymentSuccessfulModal: {
                            showModal: true,
                            redirectUrl:
                                '/dashboard/invoices/edit/add-enrollee?refCode=' +
                                response.invoice_reference_code
                        }
                    });
                } else {
                    window.location.href = response.authorization_url;
                    setTimeout(() => {
                        this.setLoading(false);
                    }, 2000);
                }
            })
            .catch((e) => {
                this.setLoading(false);
                this.setState({
                    error: {
                        message: e.response.data ? e.response.data.message : 'An Error Occurred.'
                    }
                });
            });
    };

    redirectToDashboard = () => {
        const { history } = this.props;
        history.push('/dashboard');
    };

    dismissErrorModal = () => {
        this.setState({
            error: null
        });
    };

    upgradeOrDowngradeSelectedPlans() {
        const { selectedPlans, plans, paymentFrequency } = this.state;

        const updatedSelectedPlans = selectedPlans.map((item) => ({
            ...item,
            price: plans.find((newItem) => item.id === newItem.id)?.price[paymentFrequency?.name]
        }));

        this.setState({
            selectedPlans: updatedSelectedPlans,
            previousPaymentFrequency: null,
            showChangePlanModal: false
        });
    }

    render() {
        const {
            selectedPlans,
            providersCountData,
            paymentFrequency,
            plans,
            showCheckoutModal,
            isLoading,
            isAllowed,
            error,
            paymentSuccessfulModal,
            showChangePlanModal,
            previousPaymentFrequency
        } = this.state;

        if (!isAllowed)
            return (
                <MainContent>
                    <NotificationModal
                        message={`Sorry! But you're not allowed to carry out this operation.
                                        If you need to purchase new plans, please contact our support via hellonigeria@getreliancehealth.com
                                        or call 070073542623. Thank You.`}
                        onClose={this.redirectToDashboard}
                        open={true}
                    />
                </MainContent>
            );

        if (isLoading)
            return (
                <MainContent>
                    <LoadingScreen />
                </MainContent>
            );

        if (paymentSuccessfulModal && paymentSuccessfulModal.showModal)
            return (
                <MainContent>
                    <NotificationModal
                        message={
                            'Payment successful. Please go ahead and provision the plans you purchased to their respective parties.'
                        }
                        onClose={() => this.goToAddEnrollee(paymentSuccessfulModal.redirectUrl)}
                        open={true}
                    />
                </MainContent>
            );

        if (!isLoading) {
            return (
                <MainContent>
                    <OrderSummaryModal
                        plans={selectedPlans}
                        paymentFreq={paymentFrequency.name}
                        onCheckout={this.onCheckout}
                        onClose={this.toggleCheckoutModal}
                        open={showCheckoutModal}
                    />
                    <NotificationModal
                        message={error && error.message}
                        onClose={this.dismissErrorModal}
                        open={error || false}
                    />

                    <ChangePlanModal
                        title="You are about to change your payment plan"
                        content="Changing your payment plan means that all items in your basket will be automatically removed to allow plans with new billing frequency to be added. If yes, kindly click on the Accept button"
                        isVisible={showChangePlanModal}
                        onClose={() => {
                            this.setState({
                                showChangePlanModal: false,
                                paymentFrequency: previousPaymentFrequency,
                                previousPaymentFrequency: null
                            });
                        }}
                        onYes={() => this.upgradeOrDowngradeSelectedPlans()}
                    />
                    <PageHeader title="Buy New Plans" />

                    <PageBody>
                        {selectedPlans.length > 0 && (
                            <CheckOutHeader
                                selectedPlans={selectedPlans}
                                onRemoveFromPlanList={this.onRemoveFromPlanList}
                                onRemoveAllPlans={this.onRemoveAllPlans}
                                onCheckout={this.toggleCheckoutModal}
                            />
                        )}

                        <div className="dashboard-card">
                            <p className="dashboard-card__body-text">
                                Select as many plans as you want, as well as a comfortable payment
                                plan. <br />
                                Please note that next payment(s) will be debited automatically from
                                your card.
                            </p>

                            <div className="plan-pricing-wrapper">
                                <ItemContextSwitcher
                                    items={PAYMENT_FREQUENCIES}
                                    selectedItem={paymentFrequency}
                                    onItemSelected={this.setPaymentFrequency}
                                />

                                <div className="plan-item-wrap">
                                    {plans.map((plan) => {
                                        return (
                                            <PlanComponent
                                                key={plan.id}
                                                planId={plan.id}
                                                planName={plan.name}
                                                planDescription={plan?.description || ''}
                                                providersCountData={providersCountData}
                                                planPrice={
                                                    plan.price[paymentFrequency.name?.toLowerCase()]
                                                }
                                                frequency={paymentFrequency.name.substr(
                                                    0,
                                                    paymentFrequency.name.length - 2
                                                )}
                                                onAddPlan={this.onAddToPlanList}
                                                isAdded={
                                                    selectedPlans.filter((p) => p.id === plan.id)
                                                        .length > 0
                                                }
                                                healthBenefitsUrl={healthBenefitsUrl(
                                                    plan.id,
                                                    this.props?.flags?.allowedPlanIds
                                                )}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </PageBody>
                </MainContent>
            );
        }
    }
}

class CheckOutHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDropDown: false
        };
    }

    toggleDropDown = () => {
        this.setState({
            openDropDown: !this.state.openDropDown
        });
    };

    render() {
        const { selectedPlans, onRemoveFromPlanList, onRemoveAllPlans, onCheckout } = this.props;

        return (
            <div className="dashboard-cart-container">
                <div className="dashboard-cart-header">
                    <div onClick={this.toggleDropDown}>
                        <h4 className="dashboard-card__title-text dashboard-cart-header__title-text">
                            {`  ${selectedPlans.reduce(
                                (sum, plan) => sum + plan.quantity,
                                0
                            )} Items in Cart`}
                        </h4>
                        <br />
                        <p className="dashboard-card__body-text">(Click to show/hide cart items)</p>
                    </div>
                    <button
                        className="dashboard__primary-btn dashboard-control__button"
                        onClick={onCheckout}
                    >
                        Click to Proceed
                    </button>
                </div>
                <div
                    className="dashboard-cart-body"
                    style={{ display: this.state.openDropDown ? 'block' : 'none' }}
                >
                    {selectedPlans.map((plan) => {
                        return (
                            <div key={plan.id} className="dashboard-cart-body__list-group">
                                <ul className="dashboard-cart-body__list">
                                    <li>
                                        <div className="dashboard-cart-body__list-item">
                                            <p className="dashboard-cart-body__text">{`${plan.name} x ${plan.quantity}`}</p>
                                            <button
                                                className="dashboard-cart__ghost-button"
                                                onClick={() => onRemoveFromPlanList(plan.id)}
                                            >
                                                Remove&nbsp;<i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        );
                    })}
                    <hr />
                    <div className="dashboard-cart-clear">
                        <button
                            className="dashboard__secondary-btn__danger"
                            onClick={onRemoveAllPlans}
                        >
                            REMOVE ALL
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

class PlanComponent extends React.Component {
    //name,price,id,frequency,onAdd

    constructor(props) {
        super(props);
        this.state = {
            quantity: 0,
            isAdded: false
        };
    }

    handleQuantityForm = (e) => {
        e.preventDefault();
        const { planId, planName, planPrice, onAddPlan } = this.props;
        const quantity = Number(e.target.quantity.value);
        if (quantity > 0) {
            onAddPlan(planId, planName, planPrice, quantity);
            e.target.reset();

            this.setState({
                isAdded: true
            });
            setTimeout(() => {
                this.setState({
                    isAdded: false
                });
            }, 1000);
        }
    };

    checkPlanName = () => {
        const { planName, planId, providersCountData } = this.props;

        if (planName.toLowerCase().indexOf('red beryl') > -1) {
            return {
                providersCount: providersCountData[planId],
                coverageAmount: '₦1.2 Million'
            };
        }
        if (planName.toLowerCase().indexOf('alexandrite') > -1) {
            return {
                providersCount: providersCountData[planId],
                coverageAmount: '₦1.8 Million'
            };
        }
    };

    render() {
        const { planName, planPrice, frequency, planDescription, healthBenefitsUrl } = this.props;

        return (
            <div className="plan-item ">
                <h4 className="dashboard-card__title-text  plan-item_rb">{planName}</h4>
                <div className="price-wrap">
                    <p className="plan-pricetag">
                        &#8358; {numberFormatter('#,###.00', planPrice)}
                    </p>
                    <h6 className="pricetag-duration">{`per ${frequency}`}</h6>
                </div>
                <p className="dashboard-card__body-text">
                    {planDescription}
                    {/* <span className="provider-count redBeryl">
                        Access to {this.checkPlanName.providersCount} hospitals
                    </span>
                    , Coverage for Medical Expenses up to {this.checkPlanName.coverageAmount} per
                    year,Eye care up to &#8358;15,000... */}
                </p>
                <a className="plan-link" href={healthBenefitsUrl}>
                    View comprehensive list of health benefits
                </a>
                <form onSubmit={this.handleQuantityForm} style={{ marginBottom: '15px' }}>
                    <div className="dashboard-input-wrap">
                        <input
                            className="dashboard-form__input"
                            name="quantity"
                            type="number"
                            min="1"
                            required
                            placeholder="Quantity (e.g 5)"
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <button type="submit" className="dashboard__secondary-btn plan-btn">
                            Add to plan list
                        </button>
                    </div>
                </form>
                <p className="dashboard-card__body-text">
                    {this.state.isAdded ? 'Plan added successfully' : ''}
                </p>
            </div>
        );
    }
}

PlanComponent.propTypes = {
    planId: PropTypes.number,
    planName: PropTypes.string,
    planPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    frequency: PropTypes.string,
    onAddPlan: PropTypes.func,
    isAdded: PropTypes.bool,
    planDescription: PropTypes.string,
    healthBenefitsUrl: PropTypes.string
};

CheckOutHeader.propTypes = {
    selectedPlans: PropTypes.array,
    onCheckOut: PropTypes.func,
    onRemoveFromPlanList: PropTypes.func
};

export default withFeatureFlags(NewInvoiceComponent);
