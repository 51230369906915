import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import InvoiceListContainer from '../containers/InvoiceListContainer';
import InvoiceDetailsContainer from '../containers/InvoiceDetailsContainer';
import AddEnrolleeComponent from './AddEnrolleeComponent';
import PropsRoute from './utils/PropsRoute';
import NewInvoiceComponent from './NewInvoiceComponent';
import withFeatureFlags from './utils/FeatureFlagWrapper';

const InvoiceComponent = ({ match, user, flags: { manageBilling } }) => {
    if (!manageBilling) {
        return <Redirect to="/dashboard/home" />;
    }

    return (
        <Switch>
            <PropsRoute
                exact
                path={`${match.url}/:invoiceId`}
                component={InvoiceDetailsContainer}
                user={user}
            />
            <PropsRoute
                exact
                path={`${match.url}/edit/add-enrollee`}
                component={AddEnrolleeComponent}
                user={user}
            />
            <PropsRoute
                exact
                path={`${match.url}/edit/new-invoice`}
                component={NewInvoiceComponent}
                user={user}
            />
            <PropsRoute path={match.url} component={InvoiceListContainer} user={user} />
        </Switch>
    );
};

export default withRouter(withFeatureFlags(InvoiceComponent));
