import axios from 'axios';
import * as _ from 'lodash';
import {
    BASE_URL_RELIANCE,
    DAARA_COOKIE_NAME,
    DOMAIN_COOKIE_NAME,
    getCookie
} from './utilityService';

const BASE_URL = BASE_URL_RELIANCE;

class UserService {
    constructor() {
        this.ax = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                DAARA_TOKEN: getCookie(DAARA_COOKIE_NAME)
            }
        });
        this.data = {};
        this.accessToken = getCookie(DOMAIN_COOKIE_NAME);
    }

    login(id, password) {
        this.data = {
            password,
            username: id
        };
        return this.ax
            .post(`/accounts/login`, this.data, { withCredentials: true })
            .then((response) => {
                return mapUser(response.data);
            })
            .catch((e) => {
                console.log(e);
                throw new UserServiceException(e);
            });
    }

    auth(accessToken) {
        return this.ax
            .post(`/auth/accounts`, undefined, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                response.data.data.basic_info.access_token = accessToken;
                return mapUser(response.data);
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    resetPassword(values) {
        return this.ax.post('/accounts/reset/generate', values).then((res) => {
            return res.data;
        });
    }

    verifyResetPasswordToken(values) {
        return this.ax
            .post('/accounts/reset/verify', values)
            .then((res) => {
                return res.data.data.code;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getSecurityQuestion(values) {
        return this.ax
            .post('/accounts/reset/question/generate', values)
            .then((res) => {
                return res.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    verifySecurityQuestion(values) {
        return this.ax
            .post('/accounts/reset/question/verify', values)
            .then((res) => {
                return res.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    resetPasswordNew(values) {
        return this.ax
            .post('/accounts/reset/password', values)
            .then((res) => {
                return res.data.data.token;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getEnrollees() {
        return this.ax
            .get('/enrollees', {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getEnrolleeById(uId) {
        return this.ax
            .get(`/enrollees/${uId}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getInvoices() {
        return this.ax
            .get('/accounts/invoices', {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    /**
     * Fetches an invoice by its reference code.
     *
     * @param {string} refCode - The reference code of the invoice.
     * @returns {Promise<Object>} A promise that resolves to the invoice data.
     * @throws {UserServiceException} If there is an error fetching the invoice.
     *
     * @example
     * // Example response object
     * {
     *   "autobill": 1,
     *   "new_frequency_id": null,
     *   "subscription_id": 447,
     *   "products": [
     *     {
     *       "id": 1,
     *       "name": "DIAMOND Individual",
     *       "price": "13500.00",
     *       "no_of_enrollees": 1,
     *       "no_of_available_slots": 1
     *     }
     *   ],
     *   "reference_code": "INVDD39FC92",
     *   "total_price": "13500.00",
     *   "description": "New Retail Invoice for Kelvin Omozokpia",
     *   "payment_status_id": 3,
     *   "payment_frequency_id": 3,
     *   "payment_frequency": "Monthly",
     *   "no_of_available_slots": 1,
     *   "created_at": "2024-08-15 09:04:38",
     *   "active_status_id": 1,
     *   "start_date": "2024-08-15",
     *   "end_date": "2024-09-15"
     * }
     */
    getInvoiceByRefCode(refCode) {
        return this.ax
            .get(`/accounts/invoices/${refCode}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    renewableExpiredInvoices() {
        return this.ax
            .get(`/accounts/invoices?status=4`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getInvoiceEnrollees(refCode) {
        return this.ax
            .get(`/accounts/invoices/${refCode}/enrollees`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    addEnrolleeToInvoice(json) {
        return this.ax
            .post('/enrollees', json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e, e.response.data.data);
            });
    }

    editEnrolleeDetails(userId, json) {
        return this.ax
            .put(`/enrollees/${userId}`, json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    editEnrolleeSubscription(userId, json) {
        return this.ax
            .put(`/accounts/enrollees/${userId}/subscription`, json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    renewSuspendedPlans(invoiceRefCode, user_ids) {
        return this.ax.post(`payment/manual/${invoiceRefCode}`, { user_ids }).then((response) => {
            return response.data.data;
        });
    }

    buyPlansAndExtensions(json) {
        return this.ax
            .post('/cart', json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    changePassword(json) {
        return this.ax
            .post('/accounts/change-password', json, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    transferEnrollee(eId) {
        return this.ax
            .post(`/enrollees/${eId}/transfer`, null, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    removeEnrolleeExtension(eId, extId) {
        return this.ax
            .delete(`/enrollees/${eId}/extensions/${extId}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    removeEnrollee(eId) {
        return this.ax
            .delete(`/enrollees/${eId}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    unhashParams(hash) {
        if (hash) {
            return this.ax
                .post(
                    '/enrollees/unhash',
                    { hash },
                    {
                        headers: {
                            Authorization: `Bearer ${this.accessToken}`
                        }
                    }
                )
                .then((response) => {
                    return {
                        email_address: response.data.data.email_address,
                        phone_number: response.data.data.phone_number,
                        first_name: response.data.data.first_name,
                        last_name: response.data.data.last_name,
                        is_family_plan: response.data.data.is_family_plan,
                        company_name: response.data.data.company_name,
                        providers: response.data.data.providers
                    };
                })
                .catch((e) => {
                    throw new UserServiceException(e);
                });
        } else {
            return null;
        }
    }

    register(values) {
        //delete profile picture file
        delete values.profile_picture;
        //delete access_token from post body
        delete values.access_token;

        return this.ax
            .post(`/enrollees/profile`, values, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getRenewalReasons() {
        return this.ax
            .get(`/variable-billing/options`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    sendRenewalReason(reference_code, reason) {
        return this.ax
            .put(
                `/variable-billing/reason`,
                { reference_code, reason },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    getVariableBillingInfo(invoiceRefCode, user_ids = null) {
        let data = { reference_code: invoiceRefCode };
        if (user_ids) data.user_ids = user_ids;
        return this.ax
            .post(`/variable-billing/info`, data, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    renewPlan(invoiceRefCode) {
        return this.ax.post(`payment/manual/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }

    resetPlan(invoiceRefCode) {
        return this.ax.post(`/retail/renew/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }

    getPremiumValue(accessToken, invoiceRefCode) {
        return this.ax
            .get(`/accounts/invoices/${invoiceRefCode}/premium-value`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data;
            });
    }

    toggleAutoBilling(activate, invoiceRefCode) {
        return this.ax
            .post(`/payment/${activate ? 'subscribe' : 'unsubscribe'}/${invoiceRefCode}`)
            .then((response) => {
                return response.data.message;
            });
    }

    changePaymentFrequency(accessToken, newFreq, invoiceRefCode) {
        return this.ax
            .put(
                `/accounts/invoices/${invoiceRefCode}`,
                { frequency: newFreq },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingPay(reference_code, user_ids, one_time = false) {
        return this.ax
            .post(
                `/variable-billing/pay`,
                {
                    reference_code,
                    user_ids,
                    one_time
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingRefund(refCode, accName, accNumber, bankId) {
        return this.ax
            .post(
                `/enrollees/billing/refund`,
                {
                    reference_code: refCode,
                    account_name: accName,
                    account_number: accNumber,
                    bank_id: bankId
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }

    variableBillingDismiss(refCode) {
        return this.ax
            .post(
                `/enrollees/billing/dismiss`,
                {
                    reference_code: refCode
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.message;
            })
            .catch((e) => {
                throw new UserServiceException(e);
            });
    }
}

function mapUser(json) {
    return {
        firstName: _.get(json, 'data.basic_info.first_name'),
        lastName: _.get(json, 'data.basic_info.last_name'),
        roles: _.get(json, 'data.roles'),
        email: _.get(json, 'data.basic_info.email_address'),
        phoneNumber: _.get(json, 'data.basic_info.phone_number'),
        isInternalUser: _.get(json, 'data.basic_info.is_internal_user'),
        credentials: {
            accessToken: _.get(json, 'data.basic_info.access_token'),
            daaraToken: _.get(json, 'data.basic_info.daara_token'),
            refreshToken: _.get(json, 'data.basic_info.daara_refresh_token')
        },
        preferences: {
            allowNotifications: ''
        }
    };
}

function UserServiceException(e, _meta) {
    if (!e.response)
        e = {
            response: {
                status: 0,
                statusText: 'Unknown Error',
                data: { message: 'An Error Occurred' }
            }
        };
    this.code = e.response.status;
    this.message = e.response.data.message || e.response.statusText;
    this.meta = _meta;
}

export default new UserService();
