import React from 'react';
import { PaymentFrequency } from './NewInvoiceComponent';
import { titleCase } from './utils/Constants';

const buttonClass = (index, itemId = undefined, items = []) => {
    let className = 'dashboard-content-switcher__btn';
    if (index === 0) {
        className += ' content-switcher__btn_left-curve';
    }
    if (index === items?.length - 1) {
        className += ' content-switcher__btn_right-curve';
    }
    if (itemId === items[index]?.id) {
        className += ' content-switcher__btn_active';
    }
    return className;
};

export const PercentOff = ({ percentOff }) => (
    <>
        {' - '}
        <span style={{ color: '#27ae60', fontSize: '1.25rem' }}>{percentOff}% off</span>
    </>
);

export const ItemContextSwitcher = ({ items = [], selectedItem, onItemSelected }) => {
    return (
        <div className="content-switcher plan_duration_btn_wrapper">
            {items.map((item, index) => (
                <button
                    key={`${item.id}_${(index, selectedItem?.id)}`}
                    className={`${buttonClass(index, selectedItem?.id, items)}`}
                    onClick={() => onItemSelected(item)}
                >
                    {titleCase(item.name)}

                    {item.id === PaymentFrequency?.YEARLY?.id && <PercentOff percentOff={5} />}
                </button>
            ))}
        </div>
    );
};
