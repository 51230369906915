import React from 'react';
import numberFormatter from 'number-formatter';
import { ItemContextSwitcher } from '../ItemContextSwitcher';

const PAYMENT_FREQUENCIES = [
  {id: 1, name: "monthly"},
  {id: 2, name: "quarterly"},
  {id: 3, name: "yearly"}
]

export default class ExtensionCartSummary extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      useNewCard: false,
      selectedFrequency: PAYMENT_FREQUENCIES[0]
    }
  }

  toggleUseNewCard = () => {
    this.setState({
      useNewCard: !this.state.useNewCard
    })
  };

  setPaymentFrequency = (selectedFrequency) => {
    this.setState({
      selectedFrequency
    })
  }

  render(){
    const {extensions, onPayNow} = this.props;
    const {useNewCard, selectedFrequency} = this.state;

    return (
      <div>
        <h2>Here's a summary of your order. Please confirm and make changes where necessary. Click "PAY NOW" when done to
          pay.</h2>
        <br/>
        <ItemContextSwitcher 
          items={PAYMENT_FREQUENCIES}
          selectedItem={selectedFrequency}
          onItemSelected={this.setPaymentFrequency}
        />
        <br/>
        <table className="dashboard-table">
          <thead>
          <tr>
            <th className="dashboard-table-item dashboard-table-item__header">Name</th>
            <th className="dashboard-table-item dashboard-table-item__header">Price</th>
          </tr>
          </thead>
          <tbody>
          {
            extensions.map(ext => (
              <tr key={ext.id}>
                <td className="dashboard-table-item dashboard-table-item__body">{ext.name}</td>
                <td className="dashboard-table-item dashboard-table-item__body">&#8358;{numberFormatter("#,###.00", ext.price[selectedFrequency.name])}</td>
              </tr>
            ))
  
          }
          </tbody>
        </table>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "1.5rem 2rem",
          alignItems: "center"
        }}>
          <h2 style={{fontSize: "1.5rem"}}>TOTAL</h2>
          <h3 style={{fontSize: "2rem"}}>
            &#8358; {numberFormatter("#,###.00", extensions.reduce((sum, ext) => sum + (Number(ext.price[selectedFrequency.name])), 0))}
          </h3>
        </div>
        <div onChange={this.toggleUseNewCard} style={{margin: "1.5rem 2rem", fontSize: "1.5rem"}}>
          Do you want to use a new card to pay?
          <br/>
          <br/>
          <input type="radio" value="Yes" name="use_new_card" defaultChecked={useNewCard}/> Yes
          {` `}
          <input type="radio" value="No" name="use_new_card" defaultChecked={!useNewCard}/> No, Use my previous
          card.
          <p style={{fontSize: "1.2rem", color: "#4c4c4c", fontStyle: "italic", margin: "1rem 0"}}>
            *NOTE: If you use a new card, all previous/subsequent subscription charges will also be made on the new card.
          </p>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem"
        }}>
          <button className="dashboard__primary-btn dashboard-form__btn" onClick={()=>onPayNow(useNewCard, selectedFrequency.name)} style={{width: "100%", fontSize: "1.8rem", marginTop: 0}}>
            PAY NOW
          </button>
        </div>
      </div>
    )
  }
};
