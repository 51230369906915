import React from 'react';
import { Switch } from 'react-router-dom';
import PropsRoute from './utils/PropsRoute';
import HomePageContainer from '../containers/HomePageContainer';

const HomeComponent = ({ match, user }) => {
    return (
        <Switch>
            <PropsRoute path={match.url} component={HomePageContainer} user={user} />
        </Switch>
    );
};

export default HomeComponent;
