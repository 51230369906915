import React from 'react';
import { useFeatureFlags } from '../utils/FeatureFlagWrapper';
import LanguagueSelector from '../utils/LanguageSelector';
import { useTranslation } from 'react-i18next';

const TRANSLATIONS_DICT = {
    'The type field is required.': 'resetpwd_error',
    'Email Address or HMO ID is Invalid': 'resetpwd_error2',
    'No account was found with that Email/HMO ID': 'resetpwd_error3',
    'Invalid security question answer': 'secretquestion_error2',
    'Expired Code. Please restart the process': 'secretquestion_error1',
    'Use a different password': 'newpass_error_one',
    'An Error Occurred': 'login_error',
    'The passwords you entered do not match.': 'newpass_error_two',
    undefined: 'newpass_error_two'
};

const translatedError = (error, t) => {
    return t(TRANSLATIONS_DICT[error]) || ' ';
};

const FormHolder = (props) => {
    const { t } = useTranslation('login');
    const { flags } = useFeatureFlags();
    // TODO: move the appending of img to the feature
    const logoPath = `${flags.logoName}`;
    const companyName = `${flags.companyName}`;

    return (
        <div className="dashboard" style={{ height: '100%', position: 'relative' }}>
            {flags.languageOptionsEnabled && (
                <div
                    style={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        width: 'max-content',
                        height: 'max-content',
                        padding: '20px 40px 0 0'
                    }}
                >
                    <LanguagueSelector />
                </div>
            )}
            <div className="login_form">
                <img src={logoPath} alt="Reliance Health's Logo" style={{ maxWidth: '20rem' }} />

                {props.isLogin && (
                    <div
                        className="dashboard-card__body-text"
                        dangerouslySetInnerHTML={{
                            __html: flags.languageOptionsEnabled
                                ? t('login_one')
                                : `To access all your dashboards,<br />Login with your ${companyName} account`
                        }}
                    />
                )}

                {props.message && (
                    <div
                        style={{
                            padding: '10px',
                            backgroundColor: 'red',
                            color: 'white',
                            visibility: !props.isLoading && props.message ? 'visible' : 'hidden'
                        }}
                    >
                        {translatedError(props.message, t)}
                    </div>
                )}

                {props.children}
            </div>
        </div>
    );
};

export const MessageHolder = (props) => {
    return (
        <div
            style={{
                display: 'grid',
                padding: '10px',
                margin: '0 auto',
                width: '90%',
                height: '50%',
                alignItems: 'center',
                backgroundColor: 'white',
                color: '#111'
            }}
        >
            <div>{props.children}</div>
        </div>
    );
};

export default FormHolder;
